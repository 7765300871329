<template>
  <!-- <mapLayer></mapLayer> -->
  <div id="areaShowDataDrag" class="drag-box">
    <div>
      <div id="areaShowDataDragBar" class="no-select">
        <span>区域——船舶事件统计分析</span>
        <button
          style="
            background-color: transparent;
            border: none;
            font-size: 2em;
            cursor: pointer;
            color: #ffffff;
            height: 100%;
            padding:0 10px 0 10px;
          "
          @click="setareaShowData(false)"
        >
          ×
        </button>
      </div>

      <div id="areaShowDataInjectedBox">
        <div style="padding: 20px 20px 5px 0px">

          <div class="selectInput">
            <el-select
              v-model="typeObject.typeValue"
              multiple
              collapse-tags
              style="margin-left: 10px"
              placeholder="所有类型"
              @change="filterShip"
            >
              <el-option
                v-for="item in typeObject.typeList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="selectInput">
            <el-select
              v-model="flagObject.flagValue"
              multiple
              collapse-tags
              style="margin-left: 20px"
              placeholder="所有船旗"
              @change="filterShip"
            >
              <el-option
                v-for="item in flagObject.flagList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="selectInput">
            <el-select
              v-model="tonObject.tonValue"
              multiple
              collapse-tags
              style="margin-left: 20px"
              placeholder="所有吨位"
              @change="filterShip"
            >
              <el-option
                v-for="item in tonObject.tonList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>

          <div class="selectInput">
            <el-select
              v-model="mmsiObject.mmsiValue"
              multiple
              collapse-tags
              style="margin-left: 20px"
              placeholder="所有mmsi"
              @change="filterShip"
            >
              <el-option
                v-for="item in mmsiObject.mmsiList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div style="width: 110px; margin-left: 10px; display: inline-block">
          <el-radio-group v-model="searchType" size="small" @change="getAisData">
            <el-radio-button label="实时" ></el-radio-button>
            <el-radio-button label="历史"></el-radio-button>
          </el-radio-group>
          </div>
          <div style="padding: 10px" >
            <div v-if="searchType=='历史'">
              <el-date-picker
                v-model="searchTimeRange"
                type="datetimerange"
                align="right"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                 :picker-options="pickerOptions"
                @change="getAisData"

              >
              </el-date-picker>
            </div>
          </div>

          <div style="color: white; margin: 10px 0 0 10px; ">该区域共有 {{ boatList.length }} 条船，当前筛选条件下共有 {{ filterBoatList.length }} 条船</div>
        </div>





<!-- <div style="padding: 10px">
  <div class="selectInput">
    <el-select v-model="lengthObject.lengthValue" multiple collapse-tags placeholder="所有长度" @change="filterAis">
      <el-option
        v-for="item in lengthObject.lengthList"
        :key="item"
        :label="item"
        :value="item"
      >
      </el-option>
    </el-select>
  </div>
  <div class="selectInput">
    <el-select
      v-model="value2"
      multiple
      collapse-tags
      style="margin-left: 20px"
      placeholder="所有宽度"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
  <div class="selectInput">
    <el-select
      v-model="value2"
      multiple
      collapse-tags
      style="margin-left: 20px"
      placeholder="所有设计速度"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>


</div> -->

        <div style="padding: 10px; ">
          <el-table :data="filterBoatList" style="width: 100%" height="270" @selection-change="handleSelection" @row-click="clickRow" >
            <el-table-column type="selection" label="绘制多船轨迹" width="80"></el-table-column>
            <el-table-column width="145" label="时间">
                <template slot-scope="scope">
                    {{ts_to_time(scope.row.time)}}

                </template>
            </el-table-column>
            <el-table-column prop="nameen" label="船名"> </el-table-column>
            <el-table-column prop="name" label="中文名"> </el-table-column>
            <el-table-column width="90" prop="mmsi" label="mmsi">
                <template slot-scope="scope">
                    <span :style="{ color: scope.row.isMmsiValid ? '#67C23A' : '#F56C6C' }">
                    {{ scope.row.mmsi }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="ship_type" label="类型"> </el-table-column>
            <el-table-column prop="flag" label="船旗"> </el-table-column>
            <el-table-column prop="lon" width="90" label="经度"> </el-table-column>
            <el-table-column prop="lat" label="纬度"> </el-table-column>
            <el-table-column prop="total_ton" label="吨位"> </el-table-column>
            <el-table-column prop="length" label="长度"> </el-table-column>
            <el-table-column prop="width" label="宽度"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                    <el-button type="success" v-if="scope.row.mmsi != ''" plain @click="openJoinShipWindow(true, scope.row.mmsi)">加入船队</el-button>
                </template>
            </el-table-column>
          </el-table>
        </div>

        <div style="padding: 10px" >
            <div style="width: 800px; margin-left: auto; margin-right: auto;">
                <span style="color:#ffffff">已选中 {{ boatTrackSelection.length }} 条船舶</span>
                <el-date-picker
              v-model="areaEventTimeRange"
              type="datetimerange"
              align="right"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"

              style="margin-left: 20px; "
            >
            </el-date-picker>

            <el-button
            style="margin-left: 20px; " type="primary" round @click="drawMultiTrack">绘制选中船舶轨迹</el-button>

            <el-button
            style="margin-left: 20px; " type="primary" round @click="clearMultiTrack">清除轨迹</el-button>
            </div>


            </div>

            </div>
      </div>
    </div>

</template>

<script>
import {getAisStatByIdWithShipInfo, getRegionalAisV2, getRegionalHistoryAisV2} from "@/utils/api";
import { time } from "echarts";
export default {
  name: "areaShowData",
  components: {},
  props:['boatListAll'],
  data() {
    return {
        regionId: this.$store.state.showAreaData.regionId,
        areaEventTimeRange:[new Date((+new Date()-24*3600*1000)), new Date()],
      aisData: [],
      filterAisData:[],
      ais_status_dct:{"1":"锚泊","2":"系泊","3":"海上停船","4":"减速","5":"重新航行","6":"ais信号消失","7":"ais信号出现","61":"ais信号疑似消失","8":"异常加速"},
      typeObject:{
        typeList:[],
        typeValue:[]
      },
      flagObject:{
        flagList:[],
        flagValue:[]
      },
      tonObject:{
        tonList:["未知","0-5000吨","5001-10000吨","10001-50000吨","50001-100000吨","100001吨以上"],
        tonValue:[]
      },
      lengthObject:{
        lengthList:[],
        lengthValue:[]
      },
      statusObject:{
        statusList:[],
        statusValue:[]
      },
      mmsiObject:{
        mmsiList:[],
        mmsiValue:[]
      },


      filterBoatList:[],
      boatList:[],
      boatTrackSelection:[],
      searchType:(this.$store.state.showAreaData.searchType && this.$store.state.showAreaData.searchType == 2)?"历史":"实时",
      searchTimeRange:(this.$store.state.showAreaData.st && this.$store.state.showAreaData.et) ? [new Date(this.$store.state.showAreaData.st * 1000),new Date(this.$store.state.showAreaData.et * 1000)]:[new Date((+new Date()-24*3600*1000)), new Date()],
      conditions: this.$store.state.showAreaData.conditions,
      area: this.$store.state.showAreaData.area,
      type: this.$store.state.showAreaData.type,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate;
          if (maxDate) {
            this.selectDate = null;
          }
        },
        disabledDate: (time) => {
          if (this.selectDate) {
            const threeDaysLater = new Date(this.selectDate);
            threeDaysLater.setDate(threeDaysLater.getDate() + 3);
            const threeDaysBefore = new Date(this.selectDate);
            threeDaysBefore.setDate(threeDaysBefore.getDate() - 3);
            return time < threeDaysBefore || time > threeDaysLater;
          }
          return false;
        },
      },
      selectDate: null,

    };
  },
  created(){

    this.filterBoatList = Array.from(this.boatListAll.workShipList)
    this.boatList = Array.from(this.boatListAll.workShipList)

  },
  mounted() {


    // this.getAisData()
    this.getFilterOption()

    let isMouseDown,
      initX,
      initY,
      height = areaShowDataInjectedBox.offsetHeight,
      width = areaShowDataInjectedBox.offsetWidth,
      areaShowDataDragBar = document.getElementById("areaShowDataDragBar"),
      areaShowDataDrag = document.getElementById("areaShowDataDrag");
    //   areaShowDataDragBar = document.querySelector(".no-select");

    areaShowDataDragBar.addEventListener("mousedown", function (e) {
        if(e.target.tagName != "BUTTON"){
      isMouseDown = true;
      document.body.classList.add("no-select");
      areaShowDataInjectedBox.classList.add("pointer-events");
      initX = e.offsetX;
      initY = e.offsetY;
      //   areaShowDataDrag.style.opacity = 0.5;
        }
    });

    areaShowDataDragBar.addEventListener("mouseup", function (e) {
      mouseupHandler();
    });

    document.addEventListener("mousemove", function (e) {
      if (isMouseDown) {
        var cx = e.clientX - initX,
          cy = e.clientY - initY;
        if (cx < 0) {
          cx = 0;
        }
        if (cy < 0) {
          cy = 0;
        }
        if (window.innerWidth - e.clientX + initX < width + 16) {
          cx = window.innerWidth - width;
        }
        if (e.clientY > window.innerHeight - height - areaShowDataDragBar.offsetHeight + initY) {
          cy = window.innerHeight - areaShowDataDragBar.offsetHeight - height;
        }
        areaShowDataDrag.style.left = cx + "px";
        areaShowDataDrag.style.top = cy + "px";
      }
    });

    document.addEventListener("mouseup", function (e) {
      if (
        e.clientY > window.innerWidth ||
        e.clientY < 0 ||
        e.clientX < 0 ||
        e.clientX > window.innerHeight
      ) {
        mouseupHandler();
      }
    });

    function mouseupHandler() {
      isMouseDown = false;
      document.body.classList.remove("no-select");
      areaShowDataInjectedBox.classList.remove("pointer-events");
      areaShowDataDrag.style.opacity = 1;
    }
  },
  methods: {

    clickRow(row,column,event){
        // console.log(row)
        // this.$emit("updateSearchQuery", row)
    },
    handleSelection(val){
        this.boatTrackSelection = val
        console.log(this.boatTrackSelection)
    },
    setareaShowData(visiableFlag, regionId=null) {
        if(!visiableFlag){
            this.$emit("clearAreaMarker")
            this.$emit("clearTrackCop")
        }
      this.$store.commit("SET_SHOW_AREADATA", {show:visiableFlag, regionId:regionId});
    },
    getFilterOption(){
        this.typeObject.typeList = []
        this.flagObject.flagList = []
        this.mmsiObject.mmsiList = []
        this.filterBoatList.forEach(item=>{
            if(item["ship_type"] && this.typeObject.typeList.indexOf(item["ship_type"]) == -1){
                this.typeObject.typeList.push(item["ship_type"])
            }
            if(item["flag"] && this.flagObject.flagList.indexOf(item["flag"]) == -1){
                this.flagObject.flagList.push(item["flag"])
            }
            if(item["mmsi"] && this.mmsiObject.mmsiList.indexOf(item["mmsi"]) == -1){
                this.mmsiObject.mmsiList.push(item["mmsi"])
            }
        })
    },
    judgeTonType(val){
        if(val){
            let ton = parseInt(val)
            if(ton>=0 && ton <= 5000){
                return "0-5000吨"
            }else if(ton>=5001 && ton <=10000){
                return "5001-10000吨"
            }else if(ton>=10001 && ton <=50000){
                return "10001-50000吨"
            }else if(ton>=50001 && ton <=100000){
                return "50001-100000吨"
            }else if(ton >= 100001){
                return "100001吨以上"
            }
        }else{
            return "未知"
        }
    },

    drawMultiTrack(){
        this.clearMultiTrack()
        let start = this.areaEventTimeRange[0].getTime()/1000
        let end = this.areaEventTimeRange[1].getTime()/1000
        let mmsiList = []
        if(this.boatTrackSelection.length <= 3){
            this.boatTrackSelection.forEach(item=>{
            mmsiList.push(item["mmsi"])
        })
        this.$emit("showMultiTrack",mmsiList,start,end)
        }else{
            this.$message.error("最多只能同时绘制3条轨迹")
        }

    },

    clearMultiTrack(){
        this.$emit("clearTrackCop")
    },


    getAisData(){
      if(!this.area){
        this.area = ""
        if(this.type == "RECTANGLE" || this.type == "POLYGON"){
          for(let i = 0; i < this.conditions.length; i++){
            if(i == 0){
              this.area = this.area + this.conditions[i][0] + "|" + this.conditions[i][1]
            }else{
              this.area =  this.area + "#"  + this.conditions[i][0] + "|" + this.conditions[i][1]
            }
          }
        }else{
          this.area = this.area + this.conditions[0] + "|" + this.conditions[1] + "#" + this.conditions[2]
        }
      }

      if(this.searchType == "实时"){
        getRegionalAisV2({"area":this.area,"type": this.type,"sp":0}).then((res) => {

          this.boatList = res.data.resultData;
          this.filterBoatList = this.boatList;
          this.boatList.forEach((item, index)=>{
            let a = item["ship_type"] + "";
            this.boatList[index].ship_flag = a;
            item["ship_type"] = this.translateType(item["ship_type"])
          })
          this.filterShip()
        })
      }else{

        getRegionalHistoryAisV2({"area":this.area,"type": this.type, "st": this.searchTimeRange[0].getTime()/1000, "et":this.searchTimeRange[1].getTime()/1000}).then((res) => {

          this.boatList = res.data.resultData;
          this.filterBoatList = this.boatList
          this.boatList.forEach((item, index)=>{
            let a = item["ship_type"] + "";
            this.boatList[index].ship_flag = a;
            item["ship_type"] = this.translateType(item["ship_type"])
          })
          this.filterShip()
        })

      }


    },
    translateType(type) {
      const typeMap = {
        "Offshore Support Vessel": "海上支援船",
        // 添加其他类型的映射
        "0":"未知",
        "1":"未知",
        "2":"未知",
        "3":"未知",
        "4":"未知",
        "5":"未知",
        "6":"未知",
        "7":"未知",
        "8":"未知",
        "9":"未知",
        "10":"未知",
        "11":"未知",
        "12":"未知",
        "13":"未知",
        "14":"未知",
        "15":"未知",
        "16":"未知",
        "17":"未知",
        "18":"未知",
        "19":"未知",
        "20":"地效翼船",
        "21":"地效翼船",
        "22":"地效翼船",
        "23":"地效翼船",
        "24":"地效翼船",
        "25":"地效翼船",
        "26":"地效翼船",
        "27":"地效翼船",
        "28":"地效翼船",
        "29":"地效翼船",
        "30":"渔船",
        "31":"拖船",
        "32": "拖船",
        "33": "水下作业",
        "34":"潜水作业",
        "35":"军事行动",
        "36":"帆船",
        "37":"游艇",
        "38":"未知",
        "39":"未知",
        "40":"高速轮",
        "41":"高速轮",
        "42":"高速轮",
        "43":"高速轮",
        "44":"高速轮",
        "45":"高速轮",
        "46":"高速轮",
        "47":"高速轮",
        "48":"高速轮",
        "49":"高速轮",
        "50":"引航船",
        "51":"搜救船",
        "52":"拖船",
        "53":"港口供应船",
        "54":"污染控制船",
        "55":"执法船",
        "56":"本地船舶",
        "57":"本地船舶",
        "58":"医疗运输船",
        "59":"非战斗人员船",
        "60":"客船",
        "61":"客船",
        "62":"客船",
        "63":"客船",
        "64":"客船",
        "65":"客船",
        "66":"客船",
        "67":"客船",
        "68":"客船",
        "69":"客船",
        "70":"货船",
        "71":"货船",
        "72":"货船",
        "73":"货船",
        "74":"货船",
        "75":"货船",
        "76":"货船",
        "77":"货船",
        "78":"货船",
        "79":"货船",
        "80":"油轮",
        "81":"油轮",
        "82":"油轮",
        "83":"油轮",
        "84":"油轮",
        "85":"油轮",
        "86":"油轮",
        "87":"油轮",
        "88":"油轮",
        "89":"油轮",
        "90":"其他",
        "91":"其他",
        "92":"其他",
        "93":"其他",
        "94":"其他",
        "95":"其他",
        "96":"其他",
        "97":"其他",
        "98":"其他",
        "99":"其他",


      };
      return typeMap[type] || type; // 如果找不到映射，就返回原始类型
    },
    ts_to_time(timestamp) {
        if(typeof timestamp ==='string'){
            timestamp=Number(timestamp);
        }
        if(typeof timestamp !=='number') {
            alert("输入参数无法识别为时间戳");
        }
        timestamp = timestamp * 1000
        let date = new Date(timestamp);
        let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
    },

    filterAis(){

        let typeFilter1 =  this.typeObject.typeValue.length !=0      // true 为使用此条件筛选，false为不使用
        let flagFilter1 = this.flagObject.flagValue.length != 0
        let tonFilter1 = this.tonObject.tonValue.length != 0
        let lengthFilter1 = this.lengthObject.lengthValue.length != 0
        let statusFilter1 = this.statusObject.statusValue.length != 0
        let mmsiFilter1 = this.mmsiObject.mmsiValue.length != 0
        this.filterAisData = []
        this.aisData.forEach(
            item=>{
                let typeFilter2= this.typeObject.typeValue.indexOf(item['type_name_cn']) != -1
                let flagFilter2= this.flagObject.flagValue.indexOf(item['flag']) != -1
                let tonFilter2= this.tonObject.tonValue.indexOf(item['total_ton']) != -1
                let lengthFilter2= this.lengthObject.lengthValue.indexOf(item['length']) != -1
                let statusFilter2= this.statusObject.statusValue.indexOf(item['ais_status_str']) != -1
                let mmsiFilter2 = this.mmsiObject.mmsiValue.indexOf(item['mmsi']) != -1
                if((!typeFilter1||typeFilter2) && (!flagFilter1 || flagFilter2)&& (!tonFilter1 || tonFilter2) && (!lengthFilter1 || lengthFilter2) && (!statusFilter1 || statusFilter2) && (!mmsiFilter1 || mmsiFilter2)){
                    this.filterAisData.push(item)
                }
            }
        )

    },
    filterShip(){
        let typeFilter1 =  this.typeObject.typeValue.length !=0      // true 为使用此条件筛选，false为不使用
        let flagFilter1 = this.flagObject.flagValue.length != 0
        let tonFilter1 = this.tonObject.tonValue.length != 0
        let mmsiFilter1 = this.mmsiObject.mmsiValue.length != 0
        this.filterBoatList = []
        this.boatList.forEach(

            item =>{
                let typeFilter2= this.typeObject.typeValue.indexOf(item['ship_type']) != -1
                let flagFilter2 = this.flagObject.flagValue.indexOf(item['flag']) != -1
                let tonType = this.judgeTonType(item["total_ton"])

                let tonFilter2 = this.tonObject.tonValue.indexOf(tonType) != -1
                let mmsiFilter2 = this.mmsiObject.mmsiValue.indexOf(item['mmsi']) != -1

                if((!typeFilter1||typeFilter2) && (!flagFilter1 || flagFilter2) && (!tonFilter1 || tonFilter2) && (!mmsiFilter1 || mmsiFilter2)){
                    this.filterBoatList.push(item)
                }

            }
        )
        this.getFilterOption()
        this.$emit("refreshMarkGroup",this.filterBoatList)
    },

    openJoinShipWindow(visiableFlag,mmsi=null){
        this.$store.commit("SET_SHOW_JOINSHIP", {show:false,mmsi:null});
        let that = this
        setTimeout(function(){
      that.$store.commit("SET_SHOW_JOINSHIP", {show:visiableFlag, mmsi:mmsi});
    },10)
    }



  },
};
</script>

<style scoped>
.drag-box {
  user-select: none;
  background-color: rgba(40, 40, 40, 0.5);
  z-index: 3;
  position: fixed;
  left: 255px;
  top: 230px;
  height: 520px;
  width: 900px;
}

#areaShowDataDragBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #2a3f7eeb;
  /* background: url("../../assets/img/11.png"); */
  background-repeat: no-repeat;
  color: white;
  background-size: 100%;
  /* width: 100%; */
  height: 40px;
  cursor: move;
  user-select: none;
  padding:0 0 0 10px;
}

.no-select {
  user-select: none;
}

.pointer-events {
  pointer-events: none;
}

.no-border {
  border: none;
}

#areaShowDataInjectedBox {
  /* height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: #eee; */
}

.add_area_name {
  width: 100%;
  display: flex;
  padding: 10px;
  -webkit-box-align: center;
  align-items: center;
}

.rectangle_position {
  box-sizing: border-box;
  height: 120px;
  /* overflow: auto; */
  padding: 10px;
}

.position_item {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 3px;
  color: white;
}
.latitude,
.longtitude {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.S {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc5MEYzREUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc5MEYzREYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzkwRjNEQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzkwRjNERDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgAMKzEAAACLSURBVHjaYuSdefUZAxUAEwOVwOAziAWXxKc0LUl0Mb5Z156TZBDMEP7MCZ9gYh+nF/CBxHEZRtBr/w3dvoIwjM94fhc3SV7D5i1choAtwJWOSA0jRnwJEtkFoDDCZxjewOabxYCsiQ9mOHKYERXY+MKEojCCJQdsLmIhNZawGUIw+nFpGmG5HyDAADHCQ05+Tp3dAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}
.N {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTczQTZFMjcwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTczQTZFMjgwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzNBNkUyNTBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzNBNkUyNjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlkB4AIAAABkSURBVHjaYuSdefUZAxUAEwOVwBAx6FOaliQIEytO0EW4NDGe38VNstdwGUZWGGFzAUkG8WdO+ASiP04v4KPYRXyzrj0nxov0TUcwV1HFRYQMY8GlCVtM4RIHx+xo7icIAAIMAC3mJnEAIFI3AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}

.W {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc2NDE2RDQwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc2NDE2RDUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzY0MTZEMjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzY0MTZEMzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm30OHsAAAB8SURBVHjaYuSdefUZAxUAEwOVwCA36FOaliQIkyOG1UWM53dxwzQga8aljmSvgTTCDOTPnPAJp4v4Zl17DqI/Ti/gI8bg/4ZuXwm6CNlmmO34LGAi1Wb6pyNYOKEHKC5xol2Ey1vo4izYFIFsR04jhMTBSWM09xMEAAEGAGRTQHGIQYVKAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}
.E {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAIAAADZrBkAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTcyMkM3MUUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTcyMkM3MUYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzIyQzcxQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzIyQzcxRDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjeAQ9kAAABMSURBVHjaYlRdfJOBdMDEQBagrzYWNP6tGDVMRWpLbhHQBgEyhdPQRLiMXQhrw1RHG79BwJP+rFG/Ian4dnYPQTezkOqrIZQDAAIMAA/OFUiIp0vUAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}
.NSEW {
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.selectInput {
  display: inline-block;
  width: 190px;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}

/deep/ .el-table__header .el-table-column--selection .cell .el-checkbox {
    cursor: default;
    pointer-events: none;

    &::before {
      display: inline-block;
      content: "轨迹绘制";
      color: #909399;
      font-weight: bold;
      font-size: 12px;

    }

    .el-checkbox__inner {
      display: none;
    }
  }

  ::v-deep .el-table {
  /* 允许选择文本 */
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}

/* 确保单元格内容可以选择 */
::v-deep .el-table .cell {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}



</style>
