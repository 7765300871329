<template>
    <!-- <mapLayer></mapLayer> -->
    <div id="fleetShowDataDrag" class="drag-box">
      <div>
        <div id="fleetShowDataDragBar" class="no-select">
          <span>船队——船舶事件统计分析</span>
          <button
            style="
            background-color: transparent;
            border: none;
            font-size: 2em;
            cursor: pointer;
            color: #ffffff;
            height: 100%;
            padding:0 10px 0 10px;
            "
            @click="setfleetShowData(false)"
          >
            ×
          </button>
        </div>

        <div id="fleetShowDataInjectedBox">
          <div style="padding: 10px">
            <el-date-picker
              v-model="areaEventTimeRange"
              type="datetimerange"
              align="right"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getAisData"
            >
            </el-date-picker>
            <div class="selectInput">
              <el-select
                v-model="typeObject.typeValue"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有类型"
                @change="filterAis"
              >
                <el-option
                  v-for="item in typeObject.typeList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selectInput">
              <el-select
                v-model="flagObject.flagValue"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有船旗"
                @change="filterAis"
              >
                <el-option
                  v-for="item in flagObject.flagList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selectInput">
              <el-select
                v-model="tonObject.tonValue"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有吨位"
                @change="filterAis"
              >
                <el-option
                  v-for="item in tonObject.tonList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div style="padding: 10px">
            <div class="selectInput">
              <el-select v-model="lengthObject.lengthValue" multiple collapse-tags placeholder="所有长度" @change="filterAis">
                <el-option
                  v-for="item in lengthObject.lengthList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selectInput">
              <el-select
                v-model="value2"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有宽度"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selectInput">
              <el-select
                v-model="value2"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有设计速度"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selectInput">
              <el-select
                v-model="value2"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有设计吃水"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selectInput">
              <el-select
                v-model="statusObject.statusValue"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有事件"
                @change="filterAis"
              >
                <el-option
                  v-for="item in statusObject.statusList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div style="padding: 10px; margin-top: 10px">
            <el-table :data="filterAisData" style="width: 100%" max-height="270"  @row-click="handleRowClick">
              <el-table-column width="145" prop="timeStr" label="时间"> </el-table-column>
              <el-table-column prop="name_en" label="船名"> </el-table-column>
              <el-table-column width="90" prop="mmsi" label="mmsi"> </el-table-column>
              <el-table-column prop="type_name_cn" label="类型"> </el-table-column>
              <el-table-column prop="ais_status_str" label="事件"> </el-table-column>
              <el-table-column prop="flag" label="船旗"> </el-table-column>
              <el-table-column prop="lon" width="90" label="经度"> </el-table-column>
              <el-table-column prop="lat" label="纬度"> </el-table-column>
              <el-table-column prop="total_ton" label="吨位"> </el-table-column>
              <el-table-column prop="length" label="长度"> </el-table-column>
              <el-table-column prop="wide" label="宽度"> </el-table-column>
              <el-table-column prop="design_speed" label="设计速度"> </el-table-column>
              <el-table-column prop="design_draft" label="设计吃水"> </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import {getShipAisstat} from "@/utils/api";
  import { time } from "echarts";
  export default {
    name: "fleetShowData",
    components: {},
    data() {
      return {
          fleetId: this.$store.state.showFleetData.fleetId,
          areaEventTimeRange:[new Date((+new Date()-24*3600*1000)), new Date()],
        aisData: [],
        filterAisData:[],
        ais_status_dct:{"1":"锚泊","2":"系泊","3":"海上停船","4":"减速","5":"重新航行","6":"ais信号消失","7":"ais信号出现","61":"ais信号疑似消失","8":"异常加速"},
        typeObject:{
          typeList:[],
          typeValue:[]
        },
        flagObject:{
          flagList:[],
          flagValue:[]
        },
        tonObject:{
          tonList:[],
          tonValue:[]
        },
        lengthObject:{
          lengthList:[],
          lengthValue:[]
        },
        statusObject:{
          statusList:[],
          statusValue:[]
        },
      };
    },
    mounted() {


      this.getAisData()

      let isMouseDown,
        initX,
        initY,
        height = fleetShowDataInjectedBox.offsetHeight,
        width = fleetShowDataInjectedBox.offsetWidth,
        fleetShowDataDragBar = document.getElementById("fleetShowDataDragBar"),
        fleetShowDataDrag = document.getElementById("fleetShowDataDrag");
      //   fleetShowDataDragBar = document.querySelector(".no-select");

      fleetShowDataDragBar.addEventListener("mousedown", function (e) {
        if(e.target.tagName != "BUTTON"){
        isMouseDown = true;
        document.body.classList.add("no-select");
        fleetShowDataInjectedBox.classList.add("pointer-events");
        initX = e.offsetX;
        initY = e.offsetY;
        //   fleetShowDataDrag.style.opacity = 0.5;

    }});

      fleetShowDataDragBar.addEventListener("mouseup", function (e) {
        mouseupHandler();
      });

      document.addEventListener("mousemove", function (e) {
        if (isMouseDown) {
          var cx = e.clientX - initX,
            cy = e.clientY - initY;
          if (cx < 0) {
            cx = 0;
          }
          if (cy < 0) {
            cy = 0;
          }
          if (window.innerWidth - e.clientX + initX < width + 16) {
            cx = window.innerWidth - width;
          }
          if (e.clientY > window.innerHeight - height - fleetShowDataDragBar.offsetHeight + initY) {
            cy = window.innerHeight - fleetShowDataDragBar.offsetHeight - height;
          }
          fleetShowDataDrag.style.left = cx + "px";
          fleetShowDataDrag.style.top = cy + "px";
        }
      });

      document.addEventListener("mouseup", function (e) {
        if (
          e.clientY > window.innerWidth ||
          e.clientY < 0 ||
          e.clientX < 0 ||
          e.clientX > window.innerHeight
        ) {
          mouseupHandler();
        }
      });

      function mouseupHandler() {
        isMouseDown = false;
        document.body.classList.remove("no-select");
        fleetShowDataInjectedBox.classList.remove("pointer-events");
        fleetShowDataDrag.style.opacity = 1;
      }
    },
    methods: {
        handleRowClick(row) {
        // alert(`当前行的 MMSI 信息是: ${row.fleetId}`);
    //   alert(`当前行的 MMSI 信息是: ${row.mmsi}`);
    row.fleetId = row.fleet_id
    this.$emit("updateSearchQuery", row)
    },
      setfleetShowData(visiableFlag, regionId=null) {
        this.$store.commit("SET_SHOW_FLEETDATA", {show:visiableFlag, regionId:regionId});
      },

      getAisData(){
          let fleetId = this.fleetId
          let start = this.areaEventTimeRange[0].getTime()/1000
          let end = this.areaEventTimeRange[1].getTime()/1000
          let that =this
          getShipAisstat({fleetId:fleetId, start:start.toString(), end:end.toString()}).then(res=>{
              if (res.data.resultCode == "0") {
                  that.aisData = []
                  that.filterAisData=[]
                  res.data.resultData.forEach(item=>{
                      item["timeStr"] = that.ts_to_time(item["time"])
                      item["ais_status_str"] = that.ais_status_dct[item["status"]]
                      // if(item["name_en"]){
                          that.aisData.push(item)
                          that.filterAisData.push(item)
                          if(item["type_name_cn"] && that.typeObject.typeList.indexOf(item["type_name_cn"]) == -1){
                              that.typeObject.typeList.push(item["type_name_cn"])
                          }
                          if(item["flag"] && that.flagObject.flagList.indexOf(item["flag"]) == -1){
                              that.flagObject.flagList.push(item["flag"])
                          }
                          if(item["total_ton"] && that.tonObject.tonList.indexOf(item["total_ton"]) == -1){
                              that.tonObject.tonList.push(item["total_ton"])
                          }
                          if(item["length"] && that.lengthObject.lengthList.indexOf(item["length"]) == -1){
                              that.lengthObject.lengthList.push(item["length"])
                          }
                          if(item["ais_status_str"] && that.statusObject.statusList.indexOf(item["ais_status_str"]) == -1){
                              that.statusObject.statusList.push(item["ais_status_str"])
                          }
                      // }

                  })
                //   console.log(this.aisData)
              }
          })
      },
      ts_to_time(timestamp) {
          if(typeof timestamp ==='string'){
              timestamp=Number(timestamp);
          }
          if(typeof timestamp !=='number') {
              alert("输入参数无法识别为时间戳");
          }
          timestamp = timestamp * 1000
          let date = new Date(timestamp);
          let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
      },

      filterAis(){

          let typeFilter1 =  this.typeObject.typeValue.length !=0      // true 为使用此条件筛选，false为不使用
          let flagFilter1 = this.flagObject.flagValue.length != 0
          let tonFilter1 = this.tonObject.tonValue.length != 0
          let lengthFilter1 = this.lengthObject.lengthValue.length != 0
          let statusFilter1 = this.statusObject.statusValue.length != 0
          this.filterAisData = []
          this.aisData.forEach(
              item=>{
                  let typeFilter2= this.typeObject.typeValue.indexOf(item['type_name_cn']) != -1
                  let flagFilter2= this.flagObject.flagValue.indexOf(item['flag']) != -1
                  let tonFilter2= this.tonObject.tonValue.indexOf(item['total_ton']) != -1
                  let lengthFilter2= this.lengthObject.lengthValue.indexOf(item['length']) != -1
                  let statusFilter2= this.statusObject.statusValue.indexOf(item['ais_status_str']) != -1
                  if((!typeFilter1||typeFilter2) && (!flagFilter1 || flagFilter2)&& (!tonFilter1 || tonFilter2) && (!lengthFilter1 || lengthFilter2) && (!statusFilter1 || statusFilter2)){
                      this.filterAisData.push(item)
                  }
              }
          )

      },

      openJoinShipWindow(visiableFlag,mmsi=null){
          this.$store.commit("SET_SHOW_JOINSHIP", {show:false,mmsi:null});
          let that = this
          setTimeout(function(){
        that.$store.commit("SET_SHOW_JOINSHIP", {show:visiableFlag, mmsi:mmsi});
      },10)
      }



    },
  };
  </script>

  <style scoped>
  .drag-box {
    user-select: none;
    background-color: rgba(40, 40, 40, 0.5);
    z-index: 3;
    position: fixed;
    left: 255px;
    top: 230px;
    height: 470px;
    width: 900px;
  }

  #fleetShowDataDragBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: #2a3f7eeb;
    /* background: url("../../assets/img/11.png"); */
    background-repeat: no-repeat;
    color: white;
    background-size: 100%;
    /* width: 100%; */
    height: 40px;
    cursor: move;
    user-select: none;
    padding:0 0 0 10px;
  }

  .no-select {
    user-select: none;
  }

  .pointer-events {
    pointer-events: none;
  }

  .no-border {
    border: none;
  }

  #fleetShowDataInjectedBox {
    /* height: 107px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background: #eee; */
  }

  .add_area_name {
    width: 100%;
    display: flex;
    padding: 10px;
    -webkit-box-align: center;
    align-items: center;
  }

  .rectangle_position {
    box-sizing: border-box;
    height: 120px;
    /* overflow: auto; */
    padding: 10px;
  }

  .position_item {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 3px;
    color: white;
  }
  .latitude,
  .longtitude {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .S {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc5MEYzREUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc5MEYzREYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzkwRjNEQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzkwRjNERDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgAMKzEAAACLSURBVHjaYuSdefUZAxUAEwOVwOAziAWXxKc0LUl0Mb5Z156TZBDMEP7MCZ9gYh+nF/CBxHEZRtBr/w3dvoIwjM94fhc3SV7D5i1choAtwJWOSA0jRnwJEtkFoDDCZxjewOabxYCsiQ9mOHKYERXY+MKEojCCJQdsLmIhNZawGUIw+nFpGmG5HyDAADHCQ05+Tp3dAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .N {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTczQTZFMjcwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTczQTZFMjgwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzNBNkUyNTBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzNBNkUyNjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlkB4AIAAABkSURBVHjaYuSdefUZAxUAEwOVwBAx6FOaliQIEytO0EW4NDGe38VNstdwGUZWGGFzAUkG8WdO+ASiP04v4KPYRXyzrj0nxov0TUcwV1HFRYQMY8GlCVtM4RIHx+xo7icIAAIMAC3mJnEAIFI3AAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .W {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc2NDE2RDQwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc2NDE2RDUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzY0MTZEMjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzY0MTZEMzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm30OHsAAAB8SURBVHjaYuSdefUZAxUAEwOVwCA36FOaliQIkyOG1UWM53dxwzQga8aljmSvgTTCDOTPnPAJp4v4Zl17DqI/Ti/gI8bg/4ZuXwm6CNlmmO34LGAi1Wb6pyNYOKEHKC5xol2Ey1vo4izYFIFsR04jhMTBSWM09xMEAAEGAGRTQHGIQYVKAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .E {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAIAAADZrBkAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTcyMkM3MUUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTcyMkM3MUYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzIyQzcxQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzIyQzcxRDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjeAQ9kAAABMSURBVHjaYlRdfJOBdMDEQBagrzYWNP6tGDVMRWpLbhHQBgEyhdPQRLiMXQhrw1RHG79BwJP+rFG/Ian4dnYPQTezkOqrIZQDAAIMAA/OFUiIp0vUAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .NSEW {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }

  .selectInput {
    display: inline-block;
    width: 150px;
  }

  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    width: 6px;
    background: rgba(#101f1c, 0.1);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.5);
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, 0.3);
  }
  </style>
