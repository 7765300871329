<template>
    <!-- <mapLayer></mapLayer> -->
    <div id="joinShipDrag" class="drag-box">
      <div id="joinShipDragBar" class="no-select">
        <span>加入船队</span>

        <button
          style="
            background-color: transparent;
            border: none;
            font-size: 2em;
            cursor: pointer;
            color: #ffffff;
            height: 100%;
            padding:0 10px 0 10px;
          "
          @click="setJoinShip(false)"
        >
          ×
        </button>
      </div>

      <div id="joinShipInjectedBox">
        <div style="font-size: 12px; margin: 10px 20px 0 20px; color: white">
          <span style="color: red">*</span>
          <span>加入船队后,船只将会受到监测</span>
        </div>

        <el-select
          v-model="fleetSelect.value"
          style="margin: 10px 20px 20px 20px; width: 200px"
          placeholder="请选择船队"
        >
        <el-option v-for="item in fleetSelect.option" :key="item.id" :label="item.name" :value="item.id">

        </el-option>
    </el-select>

        <div style="width: 100%; text-align: center">
          <el-row :gutter="0" style="margin-bottom: 10px">
            <el-col :span="12"><el-button @click="addShipToFleet">保存</el-button></el-col>
            <el-col :span="12"><el-button @click="setJoinShip(false)">取消</el-button></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { addAreaGroup,renameRegionGroup,getFleetsByUserId, addFleetShip} from "@/utils/api/index.js";
  export default {
    name: "areaGroupRename",
    data() {
      return {
        groupName: this.$store.state.selectedGroupName,
        fleetSelect:{
            value:"",
            option:[]
        }

      };
    },

    components: {},
    mounted() {
        this.getFleets()
      let joinShipInjectedBox = document.getElementById("joinShipInjectedBox");
      let isMouseDown,
        initX,
        initY,
        height = joinShipInjectedBox.offsetHeight,
        width = joinShipInjectedBox.offsetWidth,
        joinShipDragBar = document.getElementById("joinShipDragBar"),
        joinShipDrag = document.getElementById("joinShipDrag");
      //   joinShipDrag = document.querySelector(".drag-box");
      //   joinShipDragBar = document.querySelector(".no-select");

      joinShipDragBar.addEventListener("mousedown", function (e) {
        if(e.target.tagName != "BUTTON"){
        isMouseDown = true;
        document.body.classList.add("no-select");
        joinShipInjectedBox.classList.add("pointer-events");
        initX = e.offsetX;
        initY = e.offsetY;
        //   joinShipDrag.style.opacity = 0.5;
    }});

      joinShipDragBar.addEventListener("mouseup", function (e) {
        mouseupHandler();
      });

      document.addEventListener("mousemove", function (e) {
        if (isMouseDown) {
          var cx = e.clientX - initX,
            cy = e.clientY - initY;
          if (cx < 0) {
            cx = 0;
          }
          if (cy < 0) {
            cy = 0;
          }
          if (window.innerWidth - e.clientX + initX < width + 16) {
            cx = window.innerWidth - width;
          }
          if (e.clientY > window.innerHeight - height - joinShipDragBar.offsetHeight + initY) {
            cy = window.innerHeight - joinShipDragBar.offsetHeight - height;
          }
          joinShipDrag.style.left = cx + "px";
          joinShipDrag.style.top = cy + "px";
        }
      });

      document.addEventListener("mouseup", function (e) {
        if (
          e.clientY > window.innerWidth ||
          e.clientY < 0 ||
          e.clientX < 0 ||
          e.clientX > window.innerHeight
        ) {
          mouseupHandler();
        }
      });

      function mouseupHandler() {
        isMouseDown = false;
        document.body.classList.remove("no-select");
        joinShipInjectedBox.classList.remove("pointer-events");
        joinShipDrag.style.opacity = 1;
      }
    },
    methods: {
      setJoinShip(visiableFlag, mmsi=null) {
        this.$store.commit("SET_SHOW_JOINSHIP", {show:false,mmsi:mmsi});
      },

      getFleets(){
        let userId = localStorage.getItem("userId");
        let that = this
        getFleetsByUserId({userId:userId}).then(res=>{
            if(res.data.resultCode== "0"){
                that.fleetSelect.option = []
                res.data.resultData.forEach(
                item=>{
                    that.fleetSelect.option.push(item)
                }
            )
            }
        })
    },

    addShipToFleet() {
        let mmsi = this.$store.state.showJoinShip.mmsi
        console.log(mmsi)
        let fleetId = this.fleetSelect.value
        // if(fleetId){
          addFleetShip({mmsi:mmsi, fleetId:fleetId}).then(res=>{
            if(res.data.resultCode == 0){
              this.$emit("refreshShipList")
              this.$message({
                message: "加入成功",
                type: "success",
              });
              this.setJoinShip(false)
            }else{
              this.$message.error("加入失败");
            }
          })
        // }

      },


    },
  };
  </script>

  <style scoped>
  .drag-box {
    user-select: none;
    background-color: rgba(40, 40, 40, 0.5);
    z-index: 4;
    position: fixed;
    right: 450px;
    top: 200px;
    width: 240px;
  }

  #joinShipDragBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: #2a3f7eeb;
    color: white;
    /* width: 100%; */
    height: 40px;
    cursor: move;
    user-select: none;
    padding:0 0 0 10px;
  }

  .no-select {
    user-select: none;
  }

  .pointer-events {
    pointer-events: none;
  }

  .no-border {
    border: none;
  }

  #joinShipInjectedBox {
    /* height: 107px; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* font-size: 2rem; */
    /* background: #eee; */
  }
  </style>
