<template>
    <!-- <mapLayer></mapLayer> -->
    <div id="areaShowDataDrag" class="drag-box">
      <div>
        <div id="areaShowDataDragBar" class="no-select">
          <span>微信推送设置</span>
          <button
            style="
              background-color: transparent;
              border: none;
              font-size: 2em;
              cursor: pointer;
              color: #ffffff;
              height: 100%;
              padding:0 10px 0 10px;
            "
            @click="setShowWeixinPushSetting(false)"
          >
            ×
          </button>
        </div>
  
        <div id="areaShowDataInjectedBox">

  
  
          <div style="padding: 10px; margin: 20px;">
           <el-row :gutter="20">
            <el-col :span="12" >
                <el-table :data="wcList" style="width: 100%" height="270" @selection-change="handleSelection" @row-click="clickRow" >
              
              <el-table-column prop="nameen" label="用户名">
                <template slot-scope="scope">
                    <!-- <img style="width: 42px; height:42px;vertical-align: middle; display: inline-block;" src="../../assets/64.jpg"></img> -->
                    <span style="height:42px; display: inline-block; vertical-align: middle; line-height: 42px;margin-left: 10px;">{{ scope.row.wcId }}</span>
                </template>
            </el-table-column>
              <!-- <el-table-column prop="name" label="中文名"> </el-table-column>
              <el-table-column width="90" prop="mmsi" label="mmsi"> </el-table-column> -->
  
              <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-popconfirm  @confirm="deleteWs(scope.row.userId, scope.row.wcId)" title="确定删除此用户吗？">
                        <el-button slot="reference" type="success" @click="">删除</el-button>
                    </el-popconfirm>
                      
                  </template>
              </el-table-column>
            </el-table>
            </el-col>
            <el-col :span="12">
               <el-card style="height:270px; text-align: center;" >
                <el-image style="width: 70%; height: 70%;" :src="qrcodeUrl"></el-image>
                <el-button @click="qrCode">刷新二维码</el-button>
            </el-card>
            </el-col>
           </el-row>
          </div>
  
  
              </div>
        </div>
      </div>
    
  </template>
  
  <script>
  import {getQrcodeTicket, getWcSubList, deleteWcSub} from "@/utils/api";
  import { time } from "echarts";
  export default {
    name: "areaShowData",
    components: {},
    props:[],
    data() {
      return {



        wcList:[{}],

        boatTrackSelection:[],
        qrcodeUrl:"https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket="
      };
    },
    created(){
  
      this.wcList = []

  
    },
    mounted() {
  
  
    this.qrCode()
    this.wcSub()

    let that = this
    let timer = setInterval(() => {
      that.wcSub()
    }, 4000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer)
      timer = null
    })

  
      let isMouseDown,
        initX,
        initY,
        height = areaShowDataInjectedBox.offsetHeight,
        width = areaShowDataInjectedBox.offsetWidth,
        areaShowDataDragBar = document.getElementById("areaShowDataDragBar"),
        areaShowDataDrag = document.getElementById("areaShowDataDrag");
      //   areaShowDataDragBar = document.querySelector(".no-select");
  
      areaShowDataDragBar.addEventListener("mousedown", function (e) {
          if(e.target.tagName != "BUTTON"){
        isMouseDown = true;
        document.body.classList.add("no-select");
        areaShowDataInjectedBox.classList.add("pointer-events");
        initX = e.offsetX;
        initY = e.offsetY;
        //   areaShowDataDrag.style.opacity = 0.5;
          }
      });
  
      areaShowDataDragBar.addEventListener("mouseup", function (e) {
        mouseupHandler();
      });
  
      document.addEventListener("mousemove", function (e) {
        if (isMouseDown) {
          var cx = e.clientX - initX,
            cy = e.clientY - initY;
          if (cx < 0) {
            cx = 0;
          }
          if (cy < 0) {
            cy = 0;
          }
          if (window.innerWidth - e.clientX + initX < width + 16) {
            cx = window.innerWidth - width;
          }
          if (e.clientY > window.innerHeight - height - areaShowDataDragBar.offsetHeight + initY) {
            cy = window.innerHeight - areaShowDataDragBar.offsetHeight - height;
          }
          areaShowDataDrag.style.left = cx + "px";
          areaShowDataDrag.style.top = cy + "px";
        }
      });
  
      document.addEventListener("mouseup", function (e) {
        if (
          e.clientY > window.innerWidth ||
          e.clientY < 0 ||
          e.clientX < 0 ||
          e.clientX > window.innerHeight
        ) {
          mouseupHandler();
        }
      });
  
      function mouseupHandler() {
        isMouseDown = false;
        document.body.classList.remove("no-select");
        areaShowDataInjectedBox.classList.remove("pointer-events");
        areaShowDataDrag.style.opacity = 1;
      }
    },
    methods: {

        deleteWs(userId, wcId){
            deleteWcSub({userId: userId, wcId: wcId}).then(res=>{
                if(res.data.resultCode == 0){
                    this.wcSub()
                }

            })
        },
        qrCode(){
            const userID = localStorage.getItem("userId");
            getQrcodeTicket({userId: userID}).then(res=>{
                if(res.data.resultCode == 0){
                    this.qrcodeUrl = "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket="+res.data.resultData
                   
                }
            })
            
        },

        wcSub(){
            const userID = localStorage.getItem("userId");
            getWcSubList({userId:userID}).then(res=>{
                if(res.data.resultCode == 0){
                    this.wcList = res.data.resultData
                }
            })
        },

    setShowWeixinPushSetting(visiableFlag){
        this.$store.commit("SET_SHOW_WEIXINPUSHSETTING", {show:visiableFlag})
    },
  
      clickRow(row,column,event){
          // console.log(row)
          // this.$emit("updateSearchQuery", row)
      },
      handleSelection(val){
          this.boatTrackSelection = val
          console.log(this.boatTrackSelection)
      },



      ts_to_time(timestamp) {
          if(typeof timestamp ==='string'){
              timestamp=Number(timestamp);
          }
          if(typeof timestamp !=='number') {
              alert("输入参数无法识别为时间戳");
          }
          timestamp = timestamp * 1000
          let date = new Date(timestamp);
          let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
      },
  


  
      openJoinShipWindow(visiableFlag,mmsi=null){
          this.$store.commit("SET_SHOW_JOINSHIP", {show:false,mmsi:null});
          let that = this
          setTimeout(function(){
        that.$store.commit("SET_SHOW_JOINSHIP", {show:visiableFlag, mmsi:mmsi});
      },10)
      }
  
      
  
    },
  };
  </script>
  
  <style scoped>
  .drag-box {
    user-select: none;
    background-color: rgba(40, 40, 40, 0.5);
    z-index: 3;
    position: fixed;
    left: 255px;
    top: 230px;
    height: 400px;
    width: 750px;
  }
  
  #areaShowDataDragBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: #2a3f7eeb;
    /* background: url("../../assets/img/11.png"); */
    background-repeat: no-repeat;
    color: white;
    background-size: 100%;
    /* width: 100%; */
    height: 40px;
    cursor: move;
    user-select: none;
    padding:0 0 0 10px;
  }
  
  .no-select {
    user-select: none;
  }
  
  .pointer-events {
    pointer-events: none;
  }
  
  .no-border {
    border: none;
  }
  
  #areaShowDataInjectedBox {
    /* height: 107px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background: #eee; */
  }
  
  .add_area_name {
    width: 100%;
    display: flex;
    padding: 10px;
    -webkit-box-align: center;
    align-items: center;
  }
  
  .rectangle_position {
    box-sizing: border-box;
    height: 120px;
    /* overflow: auto; */
    padding: 10px;
  }
  
  .position_item {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 3px;
    color: white;
  }
  .latitude,
  .longtitude {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .S {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc5MEYzREUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc5MEYzREYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzkwRjNEQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzkwRjNERDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgAMKzEAAACLSURBVHjaYuSdefUZAxUAEwOVwOAziAWXxKc0LUl0Mb5Z156TZBDMEP7MCZ9gYh+nF/CBxHEZRtBr/w3dvoIwjM94fhc3SV7D5i1choAtwJWOSA0jRnwJEtkFoDDCZxjewOabxYCsiQ9mOHKYERXY+MKEojCCJQdsLmIhNZawGUIw+nFpGmG5HyDAADHCQ05+Tp3dAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .N {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTczQTZFMjcwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTczQTZFMjgwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzNBNkUyNTBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzNBNkUyNjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlkB4AIAAABkSURBVHjaYuSdefUZAxUAEwOVwBAx6FOaliQIEytO0EW4NDGe38VNstdwGUZWGGFzAUkG8WdO+ASiP04v4KPYRXyzrj0nxov0TUcwV1HFRYQMY8GlCVtM4RIHx+xo7icIAAIMAC3mJnEAIFI3AAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  
  .W {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc2NDE2RDQwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc2NDE2RDUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzY0MTZEMjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzY0MTZEMzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm30OHsAAAB8SURBVHjaYuSdefUZAxUAEwOVwCA36FOaliQIkyOG1UWM53dxwzQga8aljmSvgTTCDOTPnPAJp4v4Zl17DqI/Ti/gI8bg/4ZuXwm6CNlmmO34LGAi1Wb6pyNYOKEHKC5xol2Ey1vo4izYFIFsR04jhMTBSWM09xMEAAEGAGRTQHGIQYVKAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .E {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAIAAADZrBkAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTcyMkM3MUUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTcyMkM3MUYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzIyQzcxQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzIyQzcxRDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjeAQ9kAAABMSURBVHjaYlRdfJOBdMDEQBagrzYWNP6tGDVMRWpLbhHQBgEyhdPQRLiMXQhrw1RHG79BwJP+rFG/Ian4dnYPQTezkOqrIZQDAAIMAA/OFUiIp0vUAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .NSEW {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  
  .selectInput {
    display: inline-block;
    width: 190px;
  }
  
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    width: 6px;
    background: rgba(#101f1c, 0.1);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.5);
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, 0.3);
  }
  
  /deep/ .el-table__header .el-table-column--selection .cell .el-checkbox {
      cursor: default;
      pointer-events: none; 
  
      &::before {
        display: inline-block;
        content: "轨迹绘制"; 
        color: #909399;
        font-weight: bold;
        font-size: 12px;
  
      }
  
      .el-checkbox__inner {
        display: none; 
      }
    }
  
  
  
  </style>
  