<template>
  <!-- <mapLayer></mapLayer> -->
  <div id="dragBox" class="drag-box">
    <div id="dragBoxBar" class="no-select">
      <span>分组管理</span>
      <button
        style="
            background-color: transparent;
            border: none;
            font-size: 2em;
            cursor: pointer;
            color: #ffffff;
            height: 100%;
            padding:0 10px 0 10px;
            float:right
        "
        @click="setAreaGroupRename(false)"
        
      >
        ×
      </button>
    </div>


    <div id="injectedBox">
      <el-input
        v-model="groupName"
        style="margin: 20px; width: 200px"
        placeholder="请输入分组名"
      ></el-input>
      <div style="width: 100%; text-align: center">
        <el-row :gutter="0" style="margin-bottom: 10px">
          <el-col :span="12"><el-button @click="addNewAreaGroup">保存</el-button></el-col>
          <el-col :span="12"><el-button @click="setAreaGroupRename(false)">取消</el-button></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { addAreaGroup,renameRegionGroup } from "@/utils/api/index.js";
export default {
  name: "areaGroupRename",
  data() {
    return {
      groupName: this.$store.state.selectedGroupName,
    };
  },

  components: {},
  mounted() {
    let injectedBox = document.getElementById("injectedBox");
    let isMouseDown,
      initX,
      initY,
      height = injectedBox.offsetHeight,
      width = injectedBox.offsetWidth,
      dragBoxBar = document.getElementById("dragBoxBar"),
      dragBox = document.getElementById("dragBox");
    //   dragBox = document.querySelector(".drag-box");
    //   dragBoxBar = document.querySelector(".no-select");

    dragBoxBar.addEventListener("mousedown", function (e) {
        if(e.target.tagName != "BUTTON"){
            isMouseDown = true;
      document.body.classList.add("no-select");
      injectedBox.classList.add("pointer-events");
      initX = e.offsetX;
      initY = e.offsetY;
      //   dragBox.style.opacity = 0.5;
        }

    });

    dragBoxBar.addEventListener("mouseup", function (e) {
      mouseupHandler();
    });

    document.addEventListener("mousemove", function (e) {
      if (isMouseDown) {
        var cx = e.clientX - initX,
          cy = e.clientY - initY;
        if (cx < 0) {
          cx = 0;
        }
        if (cy < 0) {
          cy = 0;
        }
        if (window.innerWidth - e.clientX + initX < width + 16) {
          cx = window.innerWidth - width;
        }
        if (e.clientY > window.innerHeight - height - dragBoxBar.offsetHeight + initY) {
          cy = window.innerHeight - dragBoxBar.offsetHeight - height;
        }
        dragBox.style.left = cx + "px";
        dragBox.style.top = cy + "px";
      }
    });

    document.addEventListener("mouseup", function (e) {
      if (
        e.clientY > window.innerWidth ||
        e.clientY < 0 ||
        e.clientX < 0 ||
        e.clientX > window.innerHeight
      ) {
        mouseupHandler();
      }
    });

    function mouseupHandler() {
      isMouseDown = false;
      document.body.classList.remove("no-select");
      injectedBox.classList.remove("pointer-events");
      dragBox.style.opacity = 1;
    }
  },
  methods: {
    setAreaGroupRename(visiableFlag, groupId=null, groupName=null) {
      this.$store.commit("SET_SHOW_AREAGROUPRENAME", {show:visiableFlag, groupId:groupId, groupName:groupName});
    },

    addNewAreaGroup() {
        if(!this.$store.state.selectedGroupId){
            let userId = localStorage.getItem("userId");
      console.log(userId);
      addAreaGroup({ groupName: this.groupName, userId: userId }).then((res) => {
        if (res.data.resultCode == "0") {
          this.$emit("refreshAreaGroupList");
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.setAreaGroupRename(false);
        } else {
          this.$message.error("保存失败");
        }
      });
    
        }else{
            let groupId = this.$store.state.selectedGroupId
            let groupName = this.groupName
            renameRegionGroup({groupName:groupName, groupId}).then((res) => {
        if (res.data.resultCode == "0") {
          this.$emit("refreshAreaGroupList");
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.setAreaGroupRename(false);
        } else {
          this.$message.error("保存失败");
        }})
        }

    },


  },
};
</script>

<style scoped>
.drag-box {
  user-select: none;
  background-color: rgba(40, 40, 40, 0.5);
  z-index: 4;
  position: fixed;
  right: 450px;
  top: 200px;
  width: 240px;
}

#dragBoxBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #2a3f7eeb;
  color: white;
  /* width: 100%; */
  height: 40px;
  cursor: move;
  user-select: none;
  padding:0 0 0 10px;
}

.no-select {
  user-select: none;
}

.pointer-events {
  pointer-events: none;
}

.no-border {
  border: none;
}

#injectedBox {
  /* height: 107px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: 2rem; */
  /* background: #eee; */
}
</style>
