<template>

  <div>
    <button class="search-button" @click="aa">搜索</button>
    <!-- <button class="search-button" @click="aa">搜索</button> -->
  </div>
  
</template>

<script>
export default {
  name: 'buttonSearch',
  methods: {
    aa(){
      this.$emit("oo")
    }
  }
};
</script>


<style scoped>
.search-button {
  padding: 11px 20px; /* 调整 padding 以适应按钮大小 */
  border: none;
  border-radius: 5px;
  background-color: #2a3f7e; /* 按钮的背景颜色，可以根据需求修改 */
  color: white; /* 按钮文本颜色 */
  cursor: pointer;
  font-size: 14px; /* 调整字体大小 */
  font-weight: 700;
  outline: none;
}

/* 添加过渡效果，使按钮在鼠标悬停时有更自然的反馈 */
.search-button:hover {
  /* background-color: #6986a6;  */
  transition: background-color 0.3s ease;
  font-size: 15px; /* 调整字体大小 */
  font-weight: 900;
}
</style>
