<template>
  <div id="app">
    <!-- 弹窗 -->
    <div
      class="popup"
      v-if="showPopup"

      :style="{ top: popupPosition.top + 'px', left: popupPosition.left + 'px', width: popupSize.width + 'px', height: popupSize.height + 'px'}"
    >
      <!-- 弹窗标题 -->
      <div class="popup-header" @mousedown="startDrag">
        <h3>异常分析{{label? "-"+label : ""}}</h3>
        <button class="close-button" @click="closePopup">×</button>
      </div>

      <!-- 弹窗内容 -->
      <div class="popup-body" >
        <div class="internal-window" v-if="showSave">
          <div class="internal-window2">
            <span>保存条件</span>
            <button
              style="
            background-color: transparent;
            border: none;
            font-size: 2em;
            cursor: pointer;
            color: #ffffff;
            height: 100%;
            padding:0 10px 0 10px;
          "
              @click="changeShowSave(false)"
            >
              ×
            </button>
          </div>

          <div >
<!--            <el-select-->
<!--              v-model="warningGroupSelect.value"-->
<!--              style="margin: 20px; width: 200px"-->
<!--              placeholder="请选择分组"-->
<!--            >-->
<!--              <el-option v-for="item in warningGroupSelect.option" :key="item.id" :label="item.name" :value="item.id">-->

<!--              </el-option>-->
<!--            </el-select>-->
            <el-input v-model="warningName" style="margin:20px 20px 20px 20px;  width: 200px" placeholder="请输入名称"></el-input>
            <div style="width: 100%; text-align: center">
              <el-row :gutter="0" style="margin-bottom: 10px">
                <el-col :span="12"><el-button @click="saveWarning" >保存</el-button></el-col>
                <el-col :span="12"><el-button @click="setJoinShip(false)"  :disabled="!warningName">更新</el-button></el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="filters">
          <label>
            时间:
            <el-date-picker
              v-model="areaEventTimeRange"
              type="datetimerange"
              align="right"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 331px;"
            >
            </el-date-picker>
          </label>
          <label>
            区域:
            <el-select style="width: 139px" v-model="areaGroupSelect.value" placeholder="请选择区域分组" @change="getAreaList" clearable>
              <el-option v-for="item in areaGroupSelect.option" :key="item.value" :label="item.label" :value="item.value">

              </el-option>
            </el-select>
            --
            <el-select style="width: 139px" v-model="areaSelect.value" placeholder="请选择预警区域" clearable>
              <el-option v-for="item in areaSelect.option" :key="item.value" :label="item.label" :value="item.value">

              </el-option>
            </el-select>
          </label>
          <label>
            船队:
            <el-select style="width: 139px" v-model="fleetSelect.value" placeholder="请选择预警船队" clearable >
              <el-option v-for="item in fleetSelect.option" :key="item.value" :label="item.label" :value="item.value">

              </el-option>
            </el-select>
          </label>
        </div>
        <div class="filters">

          <label>
            船旗:
            <el-select style="width: 139px" v-model="flagSelect.value" placeholder="请选择船舶船旗"     multiple
                       collapse-tags filterable >
              <el-option v-for="item in flagSelect.option" :key="item.value" :label="item.label" :value="item.value">

              </el-option>
            </el-select>
          </label>
          <label>
            类型:
            <el-select style="width: 139px" v-model="typeSelect.value" placeholder="请选择船舶类型" multiple
                       collapse-tags filterable>
              <el-option v-for="item in typeSelect.option" :key="item.value" :label="item.label" :value="item.value">

              </el-option>
            </el-select>
          </label>
          <label>
            吨位:
            <el-select style="width: 139px" v-model="tonSelect.value" placeholder="请选择船舶吨位" multiple
                       collapse-tags filterable>
              <el-option v-for="item in tonSelect.option" :key="item.value" :label="item.label" :value="item.value">

              </el-option>
            </el-select>
          </label>
          <label>
            事件:
              <el-select style="width: 139px" v-model="eventSelect.value" placeholder="请选择船舶事件" multiple
                         collapse-tags filterable>
                <el-option v-for="item in eventSelect.option" :key="item.value" :label="item.label" :value="item.value">

                </el-option>
              </el-select>
          </label>
          <label>
            <el-button style="width: 85px;background-color: #2a3f7eeb;font-weight: bolder; color: white;border: 1px solid black;" @click="getAisData">查询</el-button>
          </label>
          <label>
            <el-button @click="changeShowSave(true)">保存</el-button>
          </label>


        </div>
        <el-table :data="filterAisData" :height="popupSize.height-200"  style="width: 100%"  class="data-table" @row-click="handleRowClick">
          <el-table-column width="145" prop="timeStr" label="时间"> </el-table-column>
         。 <el-table-column prop="nameEn" label="船名"> </el-table-column>
          <el-table-column width="90" prop="mmsi" label="mmsi"> </el-table-column>
          <el-table-column prop="typeNameCn" label="类型"> </el-table-column>
          <el-table-column prop="aisStatusStr" label="事件"> </el-table-column>
          <el-table-column prop="flag" label="船旗"> </el-table-column>
          <el-table-column prop="lon" width="90" label="经度"> </el-table-column>
          <el-table-column prop="lat" label="纬度"> </el-table-column>
          <el-table-column prop="totalTon" label="吨位"> </el-table-column>
          <el-table-column prop="length" label="长度"> </el-table-column>
          <el-table-column prop="wide" label="宽度"> </el-table-column>
          <el-table-column prop="designSpeed" label="设计速度"> </el-table-column>
          <el-table-column prop="designDraft" label="设计吃水"> </el-table-column>
        </el-table>

      </div>

      <div class="resize-handle" @mousedown="startResize"></div>
    </div>


  </div>
</template>

<script>
import {
  addNewMission,
  getAllFlag, getAllType,
  getFleetsByUserId,
  getGroupByUserId, getMissionGroupByUserId,
  getRegionByGroupId,
  getRegionInfoById,
  getShipAisCondition,
  addNewMissionHjt
} from "@/utils/api";

export default {
  data() {
    return {
      showPopup: true,
      popupPosition: { top: 250, left: 455 },
      popupSize: { width: 952, height: 470 },
      isDragging: false,
      isResizing: false,
      startDragPosition: { x: 0, y: 0 },
      startMousePosition: { x: 0, y: 0 },
      startPopupSize: { width: 0, height: 0 },
      areaEventTimeRange:[new Date((+new Date()-24*3600*1000)), new Date()],
      data: [], // 示例数据为空
      label: this.$store.state.showWarningData?.warningItem?.label,
      warningName: this.$store.state.showWarningData?.warningItem?.label,
      warningGroupId: this.$store.state.showWarningData?.warningItem?.groupId,
      isRequest: true,
      showSave: false,
      warningGroupSelect:{
        value:this.$store.state.showWarningData?.warningItem?.groupId,
        option:[]
      },
      areaGroupSelect:{
        value:"",
        option:[]
      },
      areaSelect:{
        value:this.$store.state.showWarningData?.warningItem?.regionId ? this.$store.state.showWarningData?.warningItem?.regionId : "",
        option:[]
      },
      fleetSelect:{
        value:this.$store.state.showWarningData?.warningItem?.fleetId ? this.$store.state.showWarningData?.warningItem?.fleetId : "",
        option:[]
      },
      flagSelect:{
        value:this.$store.state.showWarningData?.warningItem?.settingFlag ?  JSON.parse(this.$store.state.showWarningData?.warningItem?.settingFlag) : [],
        option:[]
      },
      typeSelect:{
        value: this.$store.state.showWarningData?.warningItem?.settingType ?  JSON.parse(this.$store.state.showWarningData?.warningItem?.settingType) : [],
        option:[]
      },
      tonSelect:{
        value:this.$store.state.showWarningData?.warningItem?.settingTon ?  JSON.parse(this.$store.state.showWarningData?.warningItem?.settingTon) : [],
        option:[{value:"未知",label:"未知"},{value:"0-5000吨",label:"0-5000吨"},{value:"5001-10000吨",label:"5001-10000吨"},{value:"10001-50000吨",label:"10001-50000吨"},{value:"50001-100000吨",label:"50001-100000吨"},{value:"100001吨以上",label:"100001吨以上"}]
      },
      eventSelect:{
        value:this.$store.state.showWarningData?.warningItem?.settingAis ?  JSON.parse(this.$store.state.showWarningData?.warningItem?.settingAis) : [],
        option:[{value:1,label:"锚泊"},{value:2,label:"系泊"},{value:3, label:"海上停船"},{value:4,label:"减速"},{value:5,label:"重新航行"},{value:6, label:"ais信号消失"},{value:7, label:"ais信号出现"},{value:61, label:"ais信号疑似消失"},{value:8, label:"异常加速"}]
      },
      filterAisData:[],
      aisData:[],
      ais_status_dct:{"1":"锚泊","2":"系泊","3":"海上停船","4":"减速","5":"重新航行","6":"ais信号消失","7":"ais信号出现","61":"ais信号疑似消失","8":"异常加速"},

    };
  },
  mounted() {
    this.getFleets()
    this.getAreaGroupList()
    this.getRegionAndGroup()
    this.getAisData()
    this.getTypeList()
    this.getFlagList()
  },
  updated() {

  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    startDrag(event) {
      this.isDragging = true;
      this.startMousePosition = { x: event.clientX, y: event.clientY };
      this.startDragPosition = { ...this.popupPosition };
      document.addEventListener("mousemove", this.handleDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    handleDrag(event) {
      if (this.isDragging) {
        const deltaX = event.clientX - this.startMousePosition.x;
        const deltaY = event.clientY - this.startMousePosition.y;
        this.popupPosition = {
          top: this.startDragPosition.top + deltaY,
          left: this.startDragPosition.left + deltaX,
        };
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.handleDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },


    // 调节窗口大小
    startResize(event) {
      this.isResizing = true;
      this.startMousePosition = { x: event.clientX, y: event.clientY };
      this.startPopupSize = { ...this.popupSize };
      document.addEventListener("mousemove", this.handleResize);
      document.addEventListener("mouseup", this.stopResize);
    },
    handleResize(event) {
      if (this.isResizing) {
        const deltaX = event.clientX - this.startMousePosition.x;
        const deltaY = event.clientY - this.startMousePosition.y;
        this.popupSize = {
          width: Math.max(this.startPopupSize.width + deltaX, 300), // 最小宽度 300px
          height: Math.max(this.startPopupSize.height + deltaY, 200), // 最小高度 200px
        };
      }
    },
    stopResize() {
      this.isResizing = false;
      document.removeEventListener("mousemove", this.handleResize);
      document.removeEventListener("mouseup", this.stopResize);
    },

    changeShowSave(visiable){
        this.showSave = visiable
      if(visiable){
        this.getWarningGroupList()
      }
    },

    getRegionAndGroup(){
      let regionId = this.areaSelect.value
      if(regionId){
        getRegionInfoById({regionId: regionId}).then((res)=>{
          if(res.data.resultCode== "0"){
              let shipInfo = res.data.resultData
              this.areaGroupSelect.value = shipInfo.group_id
              if(shipInfo.group_id){
                  this.getAreaList(shipInfo.group_id, false)
              }
          }
        })
      }

    },
    getWarningGroupList() {
      let userId = localStorage.getItem("userId");
      getMissionGroupByUserId({ userId: userId }).then((res) => {

        this.warningGroupSelect.option = res.data.resultData;
        // res.data.resultData.forEach((item) => {
        //   that.warningGroupOption.push({
        //     label: item.name,
        //     groupId: item.id,
        //     level: 1,
        //     children: [],
        //   });
        // });

      });
    },

    async getAisData() {

      let fleetId = this.fleetSelect.value
      let start = parseInt(this.areaEventTimeRange[0].getTime() / 1000)
      let end = parseInt(this.areaEventTimeRange[1].getTime() / 1000)
      let regionId = this.areaSelect.value
      let listFleet = [fleetId]
      if (!fleetId) {
        listFleet = []
        let userId = localStorage.getItem("userId");
        let res = await getFleetsByUserId({userId: userId})
        if(res.data.resultCode == "0"){
          res.data.resultData.forEach(item=>{
            listFleet.push(item.id)
          })
        }
      }
      // alert(regionId)
      let listRegion = regionId ? [regionId] : [];
      let res2 = await getShipAisCondition({
        start: start.toString(),
        end: end.toString(),
        listFleet: JSON.stringify(listFleet),
        listRegion:JSON.stringify(listRegion)
      })
      if (res2.data.resultCode == "0") {
        this.aisData = []
        this.filterAisData = []
        let aisEvent = res2.data.resultData.aisEvent
        let archiveJson = res2.data.resultData.archiveJson
        let empty = {
          "buildDate": "",
          "callSign": "",
          "depth": "",
          "designDraft": "",
          "designSpeed": "",
          "flag": "",
          "freeboard": "",
          "imo": "",
          "length": "",
          "loadTon": "",
          "mainEngineSpeed": "",
          "nameCn": "",
          "nameEn": "",
          "statusCode": "",
          "statusString": "",
          "totalTon": "",
          "typeCode": "",
          "typeNameCn": "",
          "typeNameEn": "",
          "wide": ""
        }
        aisEvent.forEach(item=>{
          let mmsi = item.mmsi
          if(mmsi in archiveJson){
            let newItem = Object.assign({},archiveJson[mmsi], item)
            newItem["timeStr"] = this.ts_to_time(newItem["time"])
            newItem["aisStatusStr"] = this.ais_status_dct[newItem["status"]]
            this.aisData.push(newItem)

            this.filterAisData.push(newItem)

          }
        })
        console.log(this.aisData)
        console.log(this.filterAisData)

        this.filterAis()
        this.$message.success("查询成功")
      }


    },

    getAreaList(groupId, isClear){
      getRegionByGroupId({groupId:groupId}).then(res=>{
        if(res.data.resultCode == 0){
          this.areaSelect.option = []
          if(isClear){
            this.areaSelect.value = ""
          }
          res.data.resultData.forEach(item2=>{
            this.areaSelect.option.push({
              label:item2.regionName,
              value:item2.regionId,
            })
          })
        }
      })
    },


    getAreaGroupList(){
      let userId = localStorage.getItem("userId");
      getGroupByUserId({ userId: userId }).then((res) => {
        this.areaGroupSelect.option = [];
        let that = this;
        res.data.resultData.forEach((item) => {
          that.areaGroupSelect.option.push({
            label: item.groupName,
            value: item.groupId,
          });
        });
        //   console.log(this.groupOptions)
      });
    },


    getFleets(){
      let userId = localStorage.getItem("userId");
      let that = this
      getFleetsByUserId({userId:userId}).then(res=>{
        if(res.data.resultCode== "0"){
          that.fleetSelect.option = []
          res.data.resultData.forEach(
            item=>{
              that.fleetSelect.option.push({
                label:item.name,
                value:item.id
              })
            }
          )
        }
      })

    },

    getFlagList(){

      getAllFlag({}).then(res=>{

        if(res.data.resultCode== "0"){
          this.flagSelect.option = []
          res.data.resultData.forEach(
            item=>{
              if(item){
                this.flagSelect.option.push({
                  label:item,
                  value:item
                })
              }

            }
          )
        }
      })
    },
    getTypeList(){
      getAllType({}).then(res=>{
        if(res.data.resultCode== "0"){
          this.typeSelect.option = []
          res.data.resultData.forEach(
            item=>{
              if(item){
                this.typeSelect.option.push({
                  label:item,
                  value:item
                })
              }
            }
          )
        }
      })
    },

    saveWarning(){
      let fleetId = this.fleetSelect.value ? this.fleetSelect.value : 0
      let regionId = this.areaSelect.value ? this.areaSelect.value : 0
      let userId = localStorage.getItem("userId");
      addNewMissionHjt({name:this.warningName, groupId:this.warningGroupSelect.value, status:0, fleetId:fleetId,regionId:regionId,settingType:JSON.stringify(this.typeSelect.value),settingFlag:JSON.stringify(this.flagSelect.value),settingTon:this.tonSelect.value,settingAis:JSON.stringify(this.eventSelect.value),userId:userId
      }).then(
        res=>{
          if(res.data.resultCode == '0'){

            this.$message({message:"创建成功",type:"success"})
            this.$emit("refreshWarningList")
            this.changeShowSave(false)
          }
        }
      )
    },
    judgeTonType(val){
      if(val){
        let ton = parseInt(val)
        if(ton>=0 && ton <= 5000){
          return "0-5000吨"
        }else if(ton>=5001 && ton <=10000){
          return "5001-10000吨"
        }else if(ton>=10001 && ton <=50000){
          return "10001-50000吨"
        }else if(ton>=50001 && ton <=100000){
          return "50001-100000吨"
        }else if(ton >= 100001){
          return "100001吨以上"
        }
      }else{
        return "未知"
      }
    },
    filterAis(){

      let typeFilter1 =  this.typeSelect.value.length !=0      // true 为使用此条件筛选，false为不使用
      let flagFilter1 = this.flagSelect.value.length != 0
      let tonFilter1 = this.tonSelect.value.length != 0
      let eventFilter1 = this.eventSelect.value.length != 0
      this.filterAisData = []
      this.aisData.forEach(
        item=>{
          console.log()
          let typeFilter2= this.typeSelect.value.indexOf(item['typeNameCn']) != -1
          let flagFilter2= this.flagSelect.value.indexOf(item['flag']) != -1
          let tonType = this.judgeTonType(item["totalTon"])
          let tonFilter2= this.tonSelect.value.indexOf(tonType) != -1
          let eventFilter2= this.eventSelect.value.indexOf(item['status']) != -1
          if((!typeFilter1||typeFilter2) && (!flagFilter1 || flagFilter2)&& (!tonFilter1 || tonFilter2) && (!eventFilter1 || eventFilter2)){
            this.filterAisData.push(item)
          }
        }
      )

    },

    ts_to_time(timestamp) {
      if(typeof timestamp ==='string'){
        timestamp=Number(timestamp);
      }
      if(typeof timestamp !=='number') {
        alert("输入参数无法识别为时间戳");
      }
      timestamp = timestamp * 1000
      let date = new Date(timestamp);
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    handleRowClick(row) {
        // alert(`当前行的 MMSI 信息是: ${row.fleetId}`);
    //   alert(`当前行的 MMSI 信息是: ${row.mmsi}`);
    this.$emit("updateSearchQuery", row)
    }
  },
};
</script>

<style scoped>
#app{
  position: relative;
}

.internal-window {
  position: absolute;
  left: 350px;
  top: 150px;
  z-index: 6;
  background-color: rgba(40, 40, 40, 0.5);
  width: 240px;
}
.internal-window2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #2a3f7eeb;
  color: white;
  /* width: 100%; */
  height: 40px;
  user-select: none;
  padding:0 0 0 10px;
}


.popup {
  position: fixed;
  width: 952px;
  height: 470px;
  color: white;
  background-color: rgba(40, 40, 40, 0.5);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 4;
  overflow: hidden;
}

.popup-header {
  background: #2a3f7eeb;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
}

.popup-header h3 {
  margin: 0;
}

.popup-header .close-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.popup-body {
  padding: 20px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background: #f3f4f6;
}

.resize-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  background: rgba(0, 0, 0, 0.1);
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}


</style>
