import { render, staticRenderFns } from "./areaShowData.vue?vue&type=template&id=f8e5fcde&scoped=true&"
import script from "./areaShowData.vue?vue&type=script&lang=js&"
export * from "./areaShowData.vue?vue&type=script&lang=js&"
import style0 from "./areaShowData.vue?vue&type=style&index=0&id=f8e5fcde&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8e5fcde",
  null
  
)

export default component.exports