<template>
  <div class="echart-box">
    <div class="chart-title">船 舶 类 型 分 析</div>
    <div class="close-button">      
      <i class="el-icon-circle-close"  @click="closeEchart1"></i>
    </div>
    <div id="chart" style="height: 350px; width: 469px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["chartData"],  
  data() {
    return {
      chartDom: "",
      myChart: "",
      option: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.chartDom = document.getElementById("chart");
      this.myChart = echarts.init(this.chartDom);
      this.initChart();
    });
  },

  methods: {
    initChart() {
      console.log(document.getElementById("chart"), "----d");
      // return;
      // this.chart = echarts.init(this.$el, "macarons");
      

      this.option = {
        // title: {
        //   top: "2%",
        //   text: "船 舶 类 型 分 析",
        //   // subtext: 'Fake Data',
        //   left: "center",
        // },

        title: {
          text: '船舶总数',//主标题文本
            subtext: this.chartData.number.total,//副标题文本
            left:'center',
            top:'56%',
            textStyle:{
              fontWeight: 'normal',
              fontSize: 12,
              color:'#ffffff',
              align:'center'
            },
            subtextStyle:{
                fontFamily : "微软雅黑",
                fontSize: 13,
                color:'#ffffff',
            }
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          // orient: 'vertical',
          top: "13%",
          left: "center",
          textStyle:{
                color: '#ffffff'//字体颜色
           },
        },
        series: [
          {
            name: "船舶类型",
            type: "pie",
            radius : ['27%','57%'],
                center: ['50%', '50%'],
            // color:['#5470c6', '#91cc75', '#fac858','#ee6666','#73c0de','#3ba272', '#fc8452', '#9a60b4'],
            color:['#fac858','#ee6666','#73c0de','#3ba272', '#fc8452', '#9a60b4'],
            selectedMode: "single",
            selectedOffset: 10,
            top: "23%",
            data: [
              // { value: this.chartData.number.warshipTotal, name: "军舰" },
              // { value: this.chartData.number.coastGuardTotal, name: "海警船" },
              { value: this.chartData.number.workShipTotal, name: "作业船" },
              { value: this.chartData.number.specialShipTotal, name: "特种船" },
              { value: this.chartData.number.customShipTotal, name: "客船" },
              { value: this.chartData.number.goodsShipTotal, name: "货船" },
              { value: this.chartData.number.oilShipTotal, name: "油船" },
              { value: this.chartData.number.otherShipTotal, name: "其他" },
            ],

            label: {
              formatter: "{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ",
              backgroundColor: "#F6F8FC",
              borderColor: "#8C8D8E",
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 16,
                  align: "center",
                },
                hr: {
                  borderColor: "#8C8D8E",
                  width: "100%",
                  borderWidth: 1,
                  height: 0,
                },
                b: {
                  color: "#4C5058",
                  fontSize: 10,
                  fontWeight: "bold",
                  lineHeight: 12,
                },
                c: {
                  fontSize: 10,
                },
                per: {
                  color: "#fff",
                  backgroundColor: "#4C5058",
                  padding: [2, 2],
                  borderRadius: 2,
                },
                d: {
                    fontSize: 10,
                }
              },
            },

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      this.myChart.on('legendselectchanged', (e) => {
        // console.log(e.selected)
        this.$emit("updateSelcetedValue", e.selected);
    })

      this.option && this.myChart.setOption(this.option);
    },
    closeEchart1() {
      console.error("plug in closeEchart")
      this.$emit("closeEchart");
    },
    //
  },
};
</script>

<style lang="less">
.echart-box {
  z-index: 9520;
  position: absolute;
  top: 51px;
  right: 57px;
  width: 479px;
  height: 360px;
  background-color: rgba(0, 0, 0, 0.7);
  // box-shadow: -3px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray,
  //       2px 0 3px -1px gray;
  box-shadow: 8px 8px 5px #888888;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .chart-title {
    position: absolute;
    top: 1%;
    width: 430px;
    z-index: 9277;
    text-align: center;
    right: 0;
    font-size: 21px;
    padding-top: 10px;
    color: #fff;
  }
  .close-button {
    position: absolute;
    top: 1px;
    right: 2px;
    z-index: 9277;
    font-size: 30px;
    color: #fff;
  }
}
</style>
