L.Label = L.Canvas
            .extend({
                options : {
                    collision: true,   //叠盖冲突选项
                    mode: 0              //0 default 1 create 2 select(identify)
                },

                initialize : function(options) {
                    options = L.Util.setOptions(this, options);
                    //add
                    L.Util.stamp(this);
                    this._layers = {};
                    this._labels = [];
                    this._preAdds = [];   //在Label Canvas还没有添加到map时，对addLabel的label进行记录，以便在onAdd时进行添加。
                },
                //Label Canvas添加到map时响应
                onAdd: function () {
                    L.Canvas.prototype.onAdd.call(this);
                    // Redraw vectors since canvas is cleared upon removal,
                    // in case of removing the renderer itself from the map.
                    //this._draw();
                    if (!this._map) return;
                    this._preAdds.forEach( item => {
                        item.addTo(this._map);
                        this._text(item);
                        this._labels.push(item.label);
                    });     
                    this._preAdds = [];
                },
                //外部添加label
                addLabel: function(label) {
                    const circle = new L.CircleMarker([label.position.lat, label.position.lng], {radius: 1, stroke: false, renderer: this});
                    circle.label = label;
                    if (this._map) {
                        circle.addTo(this._map);
                        this._text(circle);
                        this._labels.push(circle.label);
                    } else {
                        this._preAdds.push(circle);
                    }
                },
                //外部清除某个label
                removeLabel: function(label) {
                    const item = Object.keys(this._layers).find( key => {
                        const circle = this._layers[key];
                        return circle && circle.label && circle.label._id == label._id;
                    });
                    item && this._layers[item].removeFrom(this._map);
                    this._redraw();
                },
                //外部清除所有label
                clearLabel: function() {
                    Object.keys(this._layers).forEach( key => {
                        const circle = this._layers[key];
                        circle.removeFrom(this._map);
                    });
                    this._layers = {};
                    this._labels = [];
                    this._preAdds = [];
                    this._redraw();
                },
                //外部redraw某个label
                redrawLabel: function(label) {
                    const item = Object.keys(this._layers).find( key => {
                        const layer = this._layers[key];
                        return layer && layer.label && layer.label._id == label._id;
                    });
                    item && this._updateCircle(this._layers[item]);
                },
                //外部redraw
                redraw: function() {
                    this._redraw();
                },
                _update : function() {
                    //this._time = new Date();
                    //console.log(this._time.toString() + " update");
                    // drawn
                    this._drawn = [];
                    L.Canvas.prototype._update.call(this);
                },
                //重载，非常重要，否则layer._updatePath会调用两次
                _updatePaths: function () {
                    if (this._postponeUpdatePaths) { return; }

                    this._redrawBounds = null;
                    this._redraw();
                },
                //无需重载，调试用
                _redraw: function () {
                    //console.log(this._time.toString() + " redraw");
                    L.Canvas.prototype._redraw.call(this);
                    console.log("all " + this._labels.length + " _drawn " + this._drawn.length );
                },
                //无需重载，调试用
                _draw: function () {
                    //console.log(this._time.toString() + " draw");
                    L.Canvas.prototype._draw.call(this);
                },
                //Polyline和Polygon的更新
                _updatePoly : function(layer, closed) {
                    L.Canvas.prototype._updatePoly.call(this, layer, closed);
                    this._text(layer);
                },
                //CircleMarker的更新
                _updateCircle : function(layer) {
                    L.Canvas.prototype._updateCircle.call(this, layer);
                    this._text(layer);
                },
                //标注
                _text : function(layer) {
                    // Search positions in a circular pattern
                    const generatePositions = (radius, count) => {
                        const positions = [];
                        for (let i = 0; i < count; i++) {
                            const angle = (i * 2 * Math.PI) / count;
                            positions.push({
                                offsetX: Math.cos(angle) * radius,
                                offsetY: Math.sin(angle) * radius
                            });
                        }
                        return positions;
                    };

                    // Generate multiple circles of positions with different radii
                    const searchPositions = [
                        ...generatePositions(30, 8),   // 近距离8个方位
                        ...generatePositions(50, 12),  // 中距离12个方位
                        ...generatePositions(70, 16)   // 远距离16个方位
                    ];

                    if (layer.label && layer.label.text != undefined) {
                        layer.label.drawn = false;
                        if (!this._bounds || (layer._pxBounds && layer._pxBounds.intersects(this._bounds))) {
                            const zoom = this._map.getZoom();
                            const min = ((layer.label.zoom || {}).min || 1);
                            const max = ((layer.label.zoom || {}).max || 18);
                            if (zoom >= min && zoom <= max) {
                                this._ctx.save();
                                this._ctx.globalAlpha = 1;
                                this._ctx.globalCompositeOperation = 'source-over';

                                // Calculate label dimensions with equal padding
                                this._ctx.font = ((layer.label.font || {}).size || 12) + 'px ' + 
                                                ((layer.label.font || {}).family || 'YaHei') + ' bold';
                                const textWidth = this._ctx.measureText(layer.label.text).width;
                                const padding = ((layer.label.background || {}).padding || 5);
                                // 确保左右padding相等
                                layer.label.width = textWidth + (padding * 2);
                                layer.label.height = ((layer.label.font || {}).size || 12) + (padding * 2);

                                // Check if it's first or last label
                                const isFirstOrLast = this._labels.indexOf(layer.label) === 0 || 
                                                    this._labels.indexOf(layer.label) === this._labels.length - 1;

                                // Try each position until finding one without collision
                                let validPosition = null;
                                let minIntersections = Infinity;
                                let bestPosition = null;

                                for (const pos of searchPositions) {
                                    const bounds = L.bounds(
                                        L.point(layer._point.x + pos.offsetX - padding, 
                                               layer._point.y + pos.offsetY - padding),
                                        L.point(layer._point.x + pos.offsetX + layer.label.width + padding, 
                                               layer._point.y + pos.offsetY + layer.label.height + padding)
                                    );

                                    // Count intersections with existing labels
                                    let intersections = 0;
                                    const collision = this._drawn.some(drawnLabel => {
                                        const drawnBounds = L.bounds(
                                            L.point(drawnLabel.point.x + drawnLabel.currentOffset.offsetX - padding, 
                                                   drawnLabel.point.y + drawnLabel.currentOffset.offsetY - padding),
                                            L.point(drawnLabel.point.x + drawnLabel.currentOffset.offsetX + drawnLabel.width + padding,
                                                   drawnLabel.point.y + drawnLabel.currentOffset.offsetY + drawnLabel.height + padding)
                                        );
                                        if (drawnBounds.intersects(bounds)) {
                                            intersections++;
                                            return true;
                                        }
                                        return false;
                                    });

                                    if (!collision) {
                                        validPosition = pos;
                                        break;
                                    }

                                    // Keep track of position with minimum intersections
                                    if (intersections < minIntersections) {
                                        minIntersections = intersections;
                                        bestPosition = pos;
                                    }
                                }

                                // Use valid position, or best position for first/last labels, or skip
                                const finalPosition = validPosition || (isFirstOrLast ? bestPosition : null);
                                if (!finalPosition) {
                                    this._ctx.restore();
                                    return;
                                }

                                layer.label.currentOffset = finalPosition;

                                // Calculate closest corner for line connection
                                const labelPos = {
                                    x: layer._point.x + finalPosition.offsetX,
                                    y: layer._point.y + finalPosition.offsetY
                                };

                                const corners = [
                                    {x: labelPos.x - padding, y: labelPos.y - padding},                    // top-left
                                    {x: labelPos.x - padding + layer.label.width, y: labelPos.y - padding}, // top-right
                                    {x: labelPos.x - padding + layer.label.width, y: labelPos.y - padding + layer.label.height}, // bottom-right
                                    {x: labelPos.x - padding, y: labelPos.y - padding + layer.label.height} // bottom-left
                                ];

                                const closestCorner = corners.reduce((closest, corner) => {
                                    const distance = Math.sqrt(
                                        Math.pow(layer._point.x - corner.x, 2) + 
                                        Math.pow(layer._point.y - corner.y, 2)
                                    );
                                    return distance < closest.distance ? 
                                        {x: corner.x, y: corner.y, distance: distance} : 
                                        closest;
                                }, {x: corners[0].x, y: corners[0].y, distance: Infinity});

                                // Draw connecting line with original transparency
                                this._ctx.beginPath();
                                this._ctx.moveTo(layer._point.x, layer._point.y);
                                this._ctx.lineTo(closestCorner.x, closestCorner.y);
                                this._ctx.strokeStyle = 'rgba(0,0,0,0.5)';  // 恢复原来的透明度
                                this._ctx.lineWidth = 1;
                                this._ctx.stroke();

                                // Draw label background with border
                                if ((layer.label.background || {}).visible) {
                                    this._ctx.globalAlpha = 1;
                                    // 恢复原来的透明度
                                    this._ctx.fillStyle = isFirstOrLast ? 
                                        (this._labels.indexOf(layer.label) === 0 ? 'rgba(0,255,0,0.8)' : 'rgba(255,0,0,1)') :
                                        'rgba(255,255,255,0.8)';  // 恢复原来的白色背景透明度
                                    
                                    // 绘制圆角矩形背景
                                    const radius = 2; // 圆角半径
                                    this._ctx.beginPath();
                                    this._ctx.roundRect(
                                        labelPos.x - padding,
                                        labelPos.y - padding,
                                        layer.label.width,
                                        layer.label.height,
                                        radius
                                    );
                                    this._ctx.fill();
                                    
                                    // 绘制边框
                                    this._ctx.strokeStyle = 'rgba(0,0,0,0.2)';
                                    this._ctx.lineWidth = 1;
                                    this._ctx.stroke();
                                }
                                // Draw text
                                this._ctx.globalAlpha = 1;
                                this._ctx.textBaseline = 'top';
                                this._ctx.textAlign = 'center';  // 文字居中对齐
                                this._ctx.fillStyle = 'rgba(0,0,0,1)';
                                this._ctx.font = 'bold ' + ((layer.label.font || {}).size || 12) + 'px ' + 
                                                ((layer.label.font || {}).family || 'YaHei');
                                // 在框的中心绘制文字
                                this._ctx.fillText(
                                    layer.label.text,
                                    labelPos.x + (layer.label.width - padding * 2) / 2,  // 考虑padding居中
                                    labelPos.y
                                );

                                layer.label.point = layer._point;
                                this._drawn.push(layer.label);
                                layer.label.drawn = true;
                                this._ctx.restore();
                            }
                        }
                    }
                },
                //以下内容处理交互，点击事件
                setMode( mode ){
                    this.options.mode = mode;
                },
                //点击事件
                identify( e ) {
                    const point = e.layerPoint;
                    const ids = Object.keys(this._layers).filter( key => {
                        const layer = this._layers[key];
                        return point.x + offsetX >= layer.label.point.x + offsetX && point.y + offsetY >= layer.label.point.y + offsetY && point.x + offsetX <= layer.label.point.x + offsetX + layer.label.width && point.y + offsetY <= layer.label.point.y + offsetY + layer.label.height
                    });
                    if (ids.length > 0) {
                        return  this._layers[ids[0]].label;
                    }
                },
                //悬停时，改变鼠标
                _handleMouseHover : function(e, point) {
                    if (this.options.mode != 2) return;
                    const ids = Object.keys(this._layers).filter( key => {
                        const layer = this._layers[key];
                        return point.x + offsetX >= layer.label.point.x + offsetX && point.y + offsetY >= layer.label.point.
                        y + offsetY && point.x + offsetX <= layer.label.point.x + offsetX + layer.label.width && point.y + offsetY <= layer.label.point.y + offsetY + layer.label.height
                    });
                    if (ids.length > 0){
                        L.DomUtil.addClass(this._container,
                            'leaflet-interactive'); // change cursor
                    } else {
                        L.DomUtil.removeClass(this._container,
                            'leaflet-interactive'); // change cursor
                    }
                }
            });
            