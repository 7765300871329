<template>
  <!-- <mapLayer></mapLayer> -->
  <div id="areaShowDataDrag" class="drag-box">
    <div>
      <div id="areaShowDataDragBar" class="no-select">
        <!-- <span>新增区域</span>
        <button
          style="
            background-color: transparent;
            border: none;
            font-size: 1em;
            cursor: pointer;
            color: #ffffff;
          "
          @click="setareaShowData(false)"
        >
          ×
        </button> -->
      </div>

      <div id="areaShowDataInjectedBox"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "areaShowDataDemo",
  components: {},
  mounted() {
    let isMouseDown,
      initX,
      initY,
      height = areaShowDataInjectedBox.offsetHeight,
      width = areaShowDataInjectedBox.offsetWidth,
      areaShowDataDragBar = document.getElementById("areaShowDataDragBar"),
      areaShowDataDrag = document.getElementById("areaShowDataDrag");
    //   areaShowDataDragBar = document.querySelector(".no-select");

    areaShowDataDragBar.addEventListener("mousedown", function (e) {
      isMouseDown = true;
      document.body.classList.add("no-select");
      areaShowDataInjectedBox.classList.add("pointer-events");
      initX = e.offsetX;
      initY = e.offsetY;
      //   areaShowDataDrag.style.opacity = 0.5;
    });

    areaShowDataDragBar.addEventListener("mouseup", function (e) {
      mouseupHandler();
    });

    document.addEventListener("mousemove", function (e) {
      if (isMouseDown) {
        var cx = e.clientX - initX,
          cy = e.clientY - initY;
        if (cx < 0) {
          cx = 0;
        }
        if (cy < 0) {
          cy = 0;
        }
        if (window.innerWidth - e.clientX + initX < width + 16) {
          cx = window.innerWidth - width;
        }
        if (e.clientY > window.innerHeight - height - areaShowDataDragBar.offsetHeight + initY) {
          cy = window.innerHeight - areaShowDataDragBar.offsetHeight - height;
        }
        areaShowDataDrag.style.left = cx + "px";
        areaShowDataDrag.style.top = cy + "px";
      }
    });

    document.addEventListener("mouseup", function (e) {
      if (
        e.clientY > window.innerWidth ||
        e.clientY < 0 ||
        e.clientX < 0 ||
        e.clientX > window.innerHeight
      ) {
        mouseupHandler();
      }
    });

    function mouseupHandler() {
      isMouseDown = false;
      document.body.classList.remove("no-select");
      areaShowDataInjectedBox.classList.remove("pointer-events");
      areaShowDataDrag.style.opacity = 1;
    }
  },
  methods: {
    setareaShowData(visiableFlag) {
      this.$store.commit("SET_SHOW_areaShowData", visiableFlag);
    },
  },
};
</script>

<style scoped>
.drag-box {
  user-select: none;
  background-color: rgba(40, 40, 40, 0.5);
  z-index: 2147483647;
  position: fixed;
  left: 455px;
  top: 230px;
  width: 260px;
}

#areaShowDataDragBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* background: #2a3f7eeb; */
  background: url("../../assets/img/11.png");
  background-repeat: no-repeat;
  color: white;
  background-size: 100%;
  /* width: 100%; */
  height: 450px;
  cursor: move;
  user-select: none;
  padding: 0 10px;
}

.no-select {
  user-select: none;
}

.pointer-events {
  pointer-events: none;
}

.no-border {
  border: none;
}

#areaShowDataInjectedBox {
  /* height: 107px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: 2rem; */
  /* background: #eee; */
}

.add_area_name {
  width: 100%;
  display: flex;
  padding: 10px;
  -webkit-box-align: center;
  align-items: center;
}

.rectangle_position {
  box-sizing: border-box;
  height: 120px;
  /* overflow: auto; */
  padding: 10px;
}

.position_item {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 3px;
  color: white;
}
.latitude,
.longtitude {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.S {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc5MEYzREUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc5MEYzREYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzkwRjNEQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzkwRjNERDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgAMKzEAAACLSURBVHjaYuSdefUZAxUAEwOVwOAziAWXxKc0LUl0Mb5Z156TZBDMEP7MCZ9gYh+nF/CBxHEZRtBr/w3dvoIwjM94fhc3SV7D5i1choAtwJWOSA0jRnwJEtkFoDDCZxjewOabxYCsiQ9mOHKYERXY+MKEojCCJQdsLmIhNZawGUIw+nFpGmG5HyDAADHCQ05+Tp3dAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}
.N {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTczQTZFMjcwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTczQTZFMjgwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzNBNkUyNTBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzNBNkUyNjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlkB4AIAAABkSURBVHjaYuSdefUZAxUAEwOVwBAx6FOaliQIEytO0EW4NDGe38VNstdwGUZWGGFzAUkG8WdO+ASiP04v4KPYRXyzrj0nxov0TUcwV1HFRYQMY8GlCVtM4RIHx+xo7icIAAIMAC3mJnEAIFI3AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}

.W {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc2NDE2RDQwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc2NDE2RDUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzY0MTZEMjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzY0MTZEMzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm30OHsAAAB8SURBVHjaYuSdefUZAxUAEwOVwCA36FOaliQIkyOG1UWM53dxwzQga8aljmSvgTTCDOTPnPAJp4v4Zl17DqI/Ti/gI8bg/4ZuXwm6CNlmmO34LGAi1Wb6pyNYOKEHKC5xol2Ey1vo4izYFIFsR04jhMTBSWM09xMEAAEGAGRTQHGIQYVKAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}
.E {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAIAAADZrBkAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTcyMkM3MUUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTcyMkM3MUYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzIyQzcxQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzIyQzcxRDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjeAQ9kAAABMSURBVHjaYlRdfJOBdMDEQBagrzYWNP6tGDVMRWpLbhHQBgEyhdPQRLiMXQhrw1RHG79BwJP+rFG/Ian4dnYPQTezkOqrIZQDAAIMAA/OFUiIp0vUAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}
.NSEW {
  height: 40px;
  line-height: 40px;
  width: 40px;
}
</style>
