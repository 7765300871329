<template>
    <!-- <mapLayer></mapLayer> -->
    <div id="portBoatsDataDrag" class="drag-box">
      <div>
        <div id="portBoatsDataDragBar" class="no-select">
          <span>港口分析 </span>
          <button
            style="
              background-color: transparent;
              border: none;
              font-size: 2em;
              cursor: pointer;
              color: #ffffff;
              height: 100%;
              padding:0 10px 0 10px;
            "
            @click="setportBoatsData(false)"
          >
            ×
          </button>
        </div>


        <boatsCalculate :chartData="numbers" ></boatsCalculate>


        <div id="portBoatsDataInjectedBox">

          <div style="margin-top: -10px; margin-left: 3px;">

            <div class="selectInput">
              <el-select
                v-model="typeObject.typeValue"
                multiple
                collapse-tags
                style="margin-left: 10px"
                placeholder="所有类型"
                @change="filterShip"
              >
                <el-option
                  v-for="item in typeObject.typeList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selectInput">
              <el-select
                v-model="flagObject.flagValue"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有船旗"
                @change="filterShip"
              >
                <el-option
                  v-for="item in flagObject.flagList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="selectInput">
              <el-select
                v-model="tonObject.tonValue"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有吨位"
                @change="filterShip"
              >
                <el-option
                  v-for="item in tonObject.tonList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="selectInput">
              <el-select
                v-model="mmsiObject.mmsiValue"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="所有mmsi"
                @change="filterShip"
              >
                <el-option
                  v-for="item in mmsiObject.mmsiList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div style="color: white; margin: 10px 0 0 10px; ">该区域共有 {{ boatList.length }} 条船，当前筛选条件下共有 {{ filterBoatList.length }} 条船</div>
          </div>



          <div style="padding: 10px; ">
            <el-table :data="filterBoatList" style="width: 100%" height="270" @selection-change="handleSelection" @row-click="clickRow" >
              <!-- <el-table-column type="selection" label="绘制多船轨迹" width="80"></el-table-column> -->
              <el-table-column width="145" label="时间">
                  <template slot-scope="scope">
                      {{ts_to_time(scope.row.time)}}

                  </template>
              </el-table-column>
              <el-table-column prop="nameen" label="船名"> </el-table-column>
              <el-table-column prop="name" label="中文名"> </el-table-column>
              <el-table-column width="90" prop="mmsi" label="mmsi"> </el-table-column>
              <el-table-column prop="ship_type" label="类型"> </el-table-column>
              <el-table-column prop="flag" label="船旗"> </el-table-column>
              <el-table-column prop="lon" width="90" label="经度"> </el-table-column>
              <el-table-column prop="lat" label="纬度"> </el-table-column>
              <el-table-column prop="total_ton" label="吨位"> </el-table-column>
              <el-table-column prop="length" label="长度"> </el-table-column>
              <el-table-column prop="width" label="宽度"> </el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                      <el-button type="success" v-if="scope.row.mmsi != ''" plain @click="openJoinShipWindow(true, scope.row.mmsi)">加入船队</el-button>
                  </template>
              </el-table-column>
            </el-table>
          </div>

              </div>
        </div>
      </div>

  </template>

  <script>
  import {getAisStatByIdWithShipInfo} from "@/utils/api";
  import boatsCalculate from "../boatsCalculate.vue";
  export default {
    name: "portBoatsData",
    components: {boatsCalculate},
    props:['boatListAll', "numbers"],
    data() {
      return {
          // numbers:{
          //   number:{
          //     total: 10,
          //     workShipTotal: 6,
          //     specialShipTotal: 1,
          //     customShipTotal: 1,
          //     goodsShipTotal: 1,
          //     oilShipTotal: 1,
          //     otherShipTotal: 0,
          //   }
          // },
          regionId: this.$store.state.showAreaData.regionId,
          areaEventTimeRange:[new Date((+new Date()-24*3600*1000)), new Date()],
        aisData: [],
        filterAisData:[],
        ais_status_dct:{"1":"锚泊","2":"系泊","3":"海上停船","4":"减速","5":"重新航行","6":"ais信号消失","7":"ais信号出现","61":"ais信号疑似消失","8":"异常加速"},
        typeObject:{
          typeList:[],
          typeValue:[]
        },
        flagObject:{
          flagList:[],
          flagValue:[]
        },
        tonObject:{
          tonList:["未知","0-5000吨","5001-10000吨","10001-50000吨","50001-100000吨","100001吨以上"],
          tonValue:[]
        },
        lengthObject:{
          lengthList:[],
          lengthValue:[]
        },
        statusObject:{
          statusList:[],
          statusValue:[]
        },
        mmsiObject:{
          mmsiList:[],
          mmsiValue:[]
        },


        filterBoatList:[],
        boatList:[],
        boatTrackSelection:[]
      };
    },
    created(){

      this.filterBoatList = Array.from(this.boatListAll.workShipList)
      this.boatList = Array.from(this.boatListAll.workShipList)

    },
    mounted() {


      this.getFilterOption()

      let isMouseDown,
        initX,
        initY,
        height = portBoatsDataInjectedBox.offsetHeight,
        width = portBoatsDataInjectedBox.offsetWidth,
        portBoatsDataDragBar = document.getElementById("portBoatsDataDragBar"),
        portBoatsDataDrag = document.getElementById("portBoatsDataDrag");
      //   portBoatsDataDragBar = document.querySelector(".no-select");

      portBoatsDataDragBar.addEventListener("mousedown", function (e) {
          if(e.target.tagName != "BUTTON"){
        isMouseDown = true;
        document.body.classList.add("no-select");
        portBoatsDataInjectedBox.classList.add("pointer-events");
        initX = e.offsetX;
        initY = e.offsetY;
        //   portBoatsDataDrag.style.opacity = 0.5;
          }
      });

      portBoatsDataDragBar.addEventListener("mouseup", function (e) {
        mouseupHandler();
      });

      document.addEventListener("mousemove", function (e) {
        if (isMouseDown) {
          var cx = e.clientX - initX,
            cy = e.clientY - initY;
          if (cx < 0) {
            cx = 0;
          }
          if (cy < 0) {
            cy = 0;
          }
          if (window.innerWidth - e.clientX + initX < width + 16) {
            cx = window.innerWidth - width;
          }
          if (e.clientY > window.innerHeight - height - portBoatsDataDragBar.offsetHeight + initY) {
            cy = window.innerHeight - portBoatsDataDragBar.offsetHeight - height;
          }
          portBoatsDataDrag.style.left = cx + "px";
          portBoatsDataDrag.style.top = cy + "px";
        }
      });

      document.addEventListener("mouseup", function (e) {
        if (
          e.clientY > window.innerWidth ||
          e.clientY < 0 ||
          e.clientX < 0 ||
          e.clientX > window.innerHeight
        ) {
          mouseupHandler();
        }
      });

      function mouseupHandler() {
        isMouseDown = false;
        document.body.classList.remove("no-select");
        portBoatsDataInjectedBox.classList.remove("pointer-events");
        portBoatsDataDrag.style.opacity = 1;
      }
    },
    methods: {

      clickRow(row,column,event){
          // console.log(row)
          // this.$emit("updateSearchQuery", row)
      },
      handleSelection(val){
          this.boatTrackSelection = val
          console.log(this.boatTrackSelection)
      },
      setportBoatsData(visiableFlag, regionId=null) {
          if(!visiableFlag){
              this.$emit("clearAreaMarker")
              this.$emit("clearTrackCop")
          }
        this.$store.commit("SET_SHOW_PORTBOATS", {show:visiableFlag});
      },
      getFilterOption(){
          this.boatList.forEach(item=>{
              if(item["ship_type"] && this.typeObject.typeList.indexOf(item["ship_type"]) == -1){
                  this.typeObject.typeList.push(item["ship_type"])
              }
              if(item["flag"] && this.flagObject.flagList.indexOf(item["flag"]) == -1){
                  this.flagObject.flagList.push(item["flag"])
              }
              if(item["mmsi"] && this.mmsiObject.mmsiList.indexOf(item["mmsi"]) == -1){
                  this.mmsiObject.mmsiList.push(item["mmsi"])
              }
          })
      },
      judgeTonType(val){
          if(val){
              let ton = parseInt(val)
              if(ton>=0 && ton <= 5000){
                  return "0-5000吨"
              }else if(ton>=5001 && ton <=10000){
                  return "5001-10000吨"
              }else if(ton>=10001 && ton <=50000){
                  return "10001-50000吨"
              }else if(ton>=50001 && ton <=100000){
                  return "50001-100000吨"
              }else if(ton >= 100001){
                  return "100001吨以上"
              }
          }else{
              return "未知"
          }
      },

      drawMultiTrack(){
          this.clearMultiTrack()
          let start = this.areaEventTimeRange[0].getTime()/1000
          let end = this.areaEventTimeRange[1].getTime()/1000
          let mmsiList = []
          if(this.boatTrackSelection.length <= 3){
              this.boatTrackSelection.forEach(item=>{
              mmsiList.push(item["mmsi"])
          })
          this.$emit("showMultiTrack",mmsiList,start,end)
          }else{
              this.$message.error("最多只能同时绘制3条轨迹")
          }

      },

      clearMultiTrack(){
          this.$emit("clearTrackCop")
      },



      ts_to_time(timestamp) {
          if(typeof timestamp ==='string'){
              timestamp=Number(timestamp);
          }
          if(typeof timestamp !=='number') {
              alert("输入参数无法识别为时间戳");
          }
          timestamp = timestamp * 1000
          let date = new Date(timestamp);
          let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
      },

      filterAis(){

          let typeFilter1 =  this.typeObject.typeValue.length !=0      // true 为使用此条件筛选，false为不使用
          let flagFilter1 = this.flagObject.flagValue.length != 0
          let tonFilter1 = this.tonObject.tonValue.length != 0
          let lengthFilter1 = this.lengthObject.lengthValue.length != 0
          let statusFilter1 = this.statusObject.statusValue.length != 0
          let mmsiFilter1 = this.mmsiObject.mmsiValue.length != 0
          this.filterAisData = []
          this.aisData.forEach(
              item=>{
                  let typeFilter2= this.typeObject.typeValue.indexOf(item['type_name_cn']) != -1
                  let flagFilter2= this.flagObject.flagValue.indexOf(item['flag']) != -1
                  let tonFilter2= this.tonObject.tonValue.indexOf(item['total_ton']) != -1
                  let lengthFilter2= this.lengthObject.lengthValue.indexOf(item['length']) != -1
                  let statusFilter2= this.statusObject.statusValue.indexOf(item['ais_status_str']) != -1
                  let mmsiFilter2 = this.mmsiObject.mmsiValue.indexOf(item['mmsi']) != -1
                  if((!typeFilter1||typeFilter2) && (!flagFilter1 || flagFilter2)&& (!tonFilter1 || tonFilter2) && (!lengthFilter1 || lengthFilter2) && (!statusFilter1 || statusFilter2) && (!mmsiFilter1 || mmsiFilter2)){
                      this.filterAisData.push(item)
                  }
              }
          )

      },
      filterShip(){
          let typeFilter1 =  this.typeObject.typeValue.length !=0      // true 为使用此条件筛选，false为不使用
          let flagFilter1 = this.flagObject.flagValue.length != 0
          let tonFilter1 = this.tonObject.tonValue.length != 0
          let mmsiFilter1 = this.mmsiObject.mmsiValue.length != 0
          this.filterBoatList = []
          this.boatList.forEach(

              item =>{
                  let typeFilter2= this.typeObject.typeValue.indexOf(item['ship_type']) != -1
                  let flagFilter2 = this.flagObject.flagValue.indexOf(item['flag']) != -1
                  let tonType = this.judgeTonType(item["total_ton"])

                  let tonFilter2 = this.tonObject.tonValue.indexOf(tonType) != -1
                  let mmsiFilter2 = this.mmsiObject.mmsiValue.indexOf(item['mmsi']) != -1

                  if((!typeFilter1||typeFilter2) && (!flagFilter1 || flagFilter2) && (!tonFilter1 || tonFilter2) && (!mmsiFilter1 || mmsiFilter2)){
                      this.filterBoatList.push(item)
                  }

              }
          )
          this.$emit("refreshMarkGroupPortBoats",this.filterBoatList)
      },

      openJoinShipWindow(visiableFlag,mmsi=null){
          this.$store.commit("SET_SHOW_JOINSHIP", {show:false,mmsi:null});
          let that = this
          setTimeout(function(){
        that.$store.commit("SET_SHOW_JOINSHIP", {show:visiableFlag, mmsi:mmsi});
      },10)
      }



    },
  };
  </script>

  <style scoped>
  .drag-box {
    user-select: none;
    background-color: rgba(40, 40, 40, 0.5);
    z-index: 3;
    position: fixed;
    left: 627px;
    top: 59px;
    /* height: 470px; */
    width: 705px;
  }

  #portBoatsDataDragBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: #2a3f7eeb;
    /* background: url("../../assets/img/11.png"); */
    background-repeat: no-repeat;
    color: white;
    background-size: 100%;
    /* width: 100%; */
    height: 40px;
    cursor: move;
    user-select: none;
    padding:0 0 0 10px;
  }

  .no-select {
    user-select: none;
  }

  .pointer-events {
    pointer-events: none;
  }

  .no-border {
    border: none;
  }

  #portBoatsDataInjectedBox {
    /* height: 107px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background: #eee; */
  }

  .add_area_name {
    width: 100%;
    display: flex;
    padding: 10px;
    -webkit-box-align: center;
    align-items: center;
  }

  .rectangle_position {
    box-sizing: border-box;
    height: 120px;
    /* overflow: auto; */
    padding: 10px;
  }

  .position_item {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 3px;
    color: white;
  }
  .latitude,
  .longtitude {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .S {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc5MEYzREUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc5MEYzREYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzkwRjNEQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzkwRjNERDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgAMKzEAAACLSURBVHjaYuSdefUZAxUAEwOVwOAziAWXxKc0LUl0Mb5Z156TZBDMEP7MCZ9gYh+nF/CBxHEZRtBr/w3dvoIwjM94fhc3SV7D5i1choAtwJWOSA0jRnwJEtkFoDDCZxjewOabxYCsiQ9mOHKYERXY+MKEojCCJQdsLmIhNZawGUIw+nFpGmG5HyDAADHCQ05+Tp3dAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .N {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTczQTZFMjcwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTczQTZFMjgwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzNBNkUyNTBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzNBNkUyNjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlkB4AIAAABkSURBVHjaYuSdefUZAxUAEwOVwBAx6FOaliQIEytO0EW4NDGe38VNstdwGUZWGGFzAUkG8WdO+ASiP04v4KPYRXyzrj0nxov0TUcwV1HFRYQMY8GlCVtM4RIHx+xo7icIAAIMAC3mJnEAIFI3AAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .W {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc2NDE2RDQwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc2NDE2RDUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzY0MTZEMjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzY0MTZEMzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm30OHsAAAB8SURBVHjaYuSdefUZAxUAEwOVwCA36FOaliQIkyOG1UWM53dxwzQga8aljmSvgTTCDOTPnPAJp4v4Zl17DqI/Ti/gI8bg/4ZuXwm6CNlmmO34LGAi1Wb6pyNYOKEHKC5xol2Ey1vo4izYFIFsR04jhMTBSWM09xMEAAEGAGRTQHGIQYVKAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .E {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAIAAADZrBkAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTcyMkM3MUUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTcyMkM3MUYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzIyQzcxQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzIyQzcxRDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjeAQ9kAAABMSURBVHjaYlRdfJOBdMDEQBagrzYWNP6tGDVMRWpLbhHQBgEyhdPQRLiMXQhrw1RHG79BwJP+rFG/Ian4dnYPQTezkOqrIZQDAAIMAA/OFUiIp0vUAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .NSEW {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }

  .selectInput {
    display: inline-block;
    width: 171.5px;
  }

  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    width: 6px;
    background: rgba(#101f1c, 0.1);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.5);
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, 0.3);
  }

  /deep/ .el-table__header .el-table-column--selection .cell .el-checkbox {
      cursor: default;
      pointer-events: none;

      &::before {
        display: inline-block;
        content: "轨迹绘制";
        color: #909399;
        font-weight: bold;
        font-size: 12px;

      }

      .el-checkbox__inner {
        display: none;
      }
    }



  </style>
