<template>
    <!-- <mapLayer></mapLayer> -->
    <div id="batchImportShipDrag" class="drag-box">
      <div>
        <div id="batchImportShipDragBar" class="no-select">
          <span>船舶批量导入</span>
          <button
            style="
            background-color: transparent;
            border: none;
            font-size: 2em;
            cursor: pointer;
            color: #ffffff;
            height: 100%;
            padding:0 10px 0 10px;
            "
            @click="setbatchImportShip(false)"
          >
            ×
          </button>
        </div>
  
        <div id="batchImportShipInjectedBox">
            <!-- <div class="in-file">
			<input type="file" @change="inFile" multiple="multiple" accept="*/*" />
		</div> -->
		<!-- ElementUI上传 -->
         <div style="margin:10px;padding: 10px; background-color: white">
            <el-upload
               
    
				:auto-upload="false"
				:on-change="handleChange"
                :on-remove="removeFile"

				ref="upload"
				class="upload-demo"
				accept=".txt"
                action=""
                >
            

			<el-button slot="trigger" size="mini" type="success" plain>选取文件</el-button>
            <el-select
                v-model="fleetSelect.value"
                style="margin: 20px; width: 200px"
                placeholder="请选择船队"
                >
                <el-option v-for="item in fleetSelect.option" :key="item.id" :label="item.name" :value="item.id">

                </el-option>
            </el-select>
            <el-button type="primarr" @click="batchImport">开始导入</el-button>
            <el-button @click="downloadEx">下载示例文件</el-button>
			<div slot="tip" class="el-upload__tip">上传txt格式文件，文件的每一行填写一个mmsi</div>
		</el-upload>

         </div>
		

          <div style="padding: 10px; margin-top: 10px">
            <el-table :data="shipList" style="width: 100%" max-height="240" @select="selectionRow"
            @select-all="selectionRowAll">
              <!-- <el-table-column width="145" prop="timeStr" label="时间"> </el-table-column>
              <el-table-column prop="name_en" label="船名"> </el-table-column> -->
              <el-table-column type="selection" :selectable="checkSelectable"></el-table-column>
              <el-table-column  prop="mmsi" label="mmsi"> </el-table-column>
              
              <el-table-column label="状态">
                <template slot-scope="scope" ><div :class="scope.row.state==0?'gray' : scope.row.state==1?'green':'red'">{{ stateDct[scope.row.state] }}</div></template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  import { getFleetsByUserId, addFleetShip} from "@/utils/api/index.js";
  import { time } from "echarts";
  export default {
    name: "batchImportShip",
    components: {},
    data() {
      return {
        files: null, //文件列表
          fleetId: null,
          shipList:[],

          fleetSelect:{
            value:"",
            option:[]
        },
        stateDct:{
            0:"等待导入",
            1:"导入成功",
            2:"mmsi格式不合法"
        },
        selectRowArray:[]
 
      };
    },
    mounted() {
  

        this.getFleets()
      let isMouseDown,
        initX,
        initY,
        height = batchImportShipInjectedBox.offsetHeight,
        width = batchImportShipInjectedBox.offsetWidth,
        batchImportShipDragBar = document.getElementById("batchImportShipDragBar"),
        batchImportShipDrag = document.getElementById("batchImportShipDrag");
      //   batchImportShipDragBar = document.querySelector(".no-select");
  
      batchImportShipDragBar.addEventListener("mousedown", function (e) {
        if(e.target.tagName != "BUTTON"){
        isMouseDown = true;
        document.body.classList.add("no-select");
        batchImportShipInjectedBox.classList.add("pointer-events");
        initX = e.offsetX;
        initY = e.offsetY;
        //   batchImportShipDrag.style.opacity = 0.5;
      
    }});
  
      batchImportShipDragBar.addEventListener("mouseup", function (e) {
        mouseupHandler();
      });
  
      document.addEventListener("mousemove", function (e) {
        if (isMouseDown) {
          var cx = e.clientX - initX,
            cy = e.clientY - initY;
          if (cx < 0) {
            cx = 0;
          }
          if (cy < 0) {
            cy = 0;
          }
          if (window.innerWidth - e.clientX + initX < width + 16) {
            cx = window.innerWidth - width;
          }
          if (e.clientY > window.innerHeight - height - batchImportShipDragBar.offsetHeight + initY) {
            cy = window.innerHeight - batchImportShipDragBar.offsetHeight - height;
          }
          batchImportShipDrag.style.left = cx + "px";
          batchImportShipDrag.style.top = cy + "px";
        }
      });
  
      document.addEventListener("mouseup", function (e) {
        if (
          e.clientY > window.innerWidth ||
          e.clientY < 0 ||
          e.clientX < 0 ||
          e.clientX > window.innerHeight
        ) {
          mouseupHandler();
        }
      });
  
      function mouseupHandler() {
        isMouseDown = false;
        document.body.classList.remove("no-select");
        batchImportShipInjectedBox.classList.remove("pointer-events");
        batchImportShipDrag.style.opacity = 1;
      }
    },


    methods: {
        checkSelectable(row){
            return row.state ==0 
        },

        getFleets(){
        let userId = localStorage.getItem("userId");
        let that = this
        getFleetsByUserId({userId:userId}).then(res=>{
            if(res.data.resultCode== "0"){
                that.fleetSelect.option = []
                res.data.resultData.forEach(
                item=>{
                    that.fleetSelect.option.push(item)
                }
            )
            }
        })
    },
        removeFile(){
            this.shipList=[]
        },
        setbatchImportShip(visiableFlag, regionId=null) {
        this.$emit("setBatchImportShip", visiableFlag)
      }, 
			/**
			 * input-file调用此函数时，默认传入"$event"
			 * @param e {@link Object}：$event事件对象；
			 */
			inFile(e) {
				this.files = (e.target || e.srcElement).files;  //获取"input-file"的FileList对象
				if (!this.files || !this.files.length)
					return;
				for (let i = 0, len = this.files.length; i < len; i++)
					this.read(this.files[i]);
			},
			/**
			 * 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用。
			 * @param f {@link Object}：当前上传的文件；
			 * @param fs {@link Array}：当前文件列表；
			 */
			elInFile(f, fs) {
				this.read(f.raw);
			},
			/**
			 * 解析
			 * @param f {@link File}
			 */
			read(f) {
				let rd = new FileReader();
				rd.onload = e => {  //this.readAsArrayBuffer函数内，会回调this.onload函数。在这里处理结果
					let cont = rd.reading({encode: 'UTF-8'});
                    let arr = cont.split(/[\s\n]/)
                    this.shipList = []
                    arr.forEach(item=>{
                        if(item){
                            let reg = /[0-9]{9}/
                            let state = reg.test(item) ? 0 : 2; // 判断mmsi是否合法
                            this.shipList.push({mmsi:item,selected:0,state:state})

                        }
                    })
					console.log('文件内容：', cont);
				};
				rd.readAsBinaryString(f);
			},
            handleChange(file, fileList) {
                if (fileList.length > 1) {
                    fileList.splice(0, 1);
                }
                 this.elInFile(file,fileList)
             },


            batchImport(){
                if(this.fleetSelect.value){
                    this.selectRowArray.forEach(item=>{
                    if(item.state == 0){
                        let mmsi = item.mmsi
                   
                        let fleetId = this.fleetSelect.value
                        item.state = 1
                        addFleetShip({mmsi:mmsi, fleetId:fleetId}).then(res=>{
                        if(res.data.resultCode == 0){
                            item.state = 1

                                }else{
                                    item.state = 2
                                }
                                })
                    }

                })

                this.$emit("refreshShipList")
                }else{
                    this.$message.error("请选择船队")
                }

            },
            downloadEx(){
                let a = document.createElement('a')
                a.href=`/static/mmsi.txt`
                a.download = `example.txt`
                a.click()
            },



                     // 表格复选框事件
         selectionRow(selection, row){
                this.selectRowArray = selection;
            },

            //表格全选事件
            selectionRowAll(selection){
                this.selectRowArray = selection;
            },
		},
		beforeCreate() {
			/**
			 * 读取文件（自定义函数）
			 * @param pms {@link Object}：参数：
			 *  @param pms.encode {@link String}：编码格式：
			 * @return {@link String}：文本内容；
			 */
			FileReader.prototype.reading = function ({encode} = pms) {
				let bytes = new Uint8Array(this.result);    //无符号整型数组
				let text = new TextDecoder(encode || 'UTF-8').decode(bytes);
				return text;
			};
			/* 重写readAsBinaryString函数 */
			FileReader.prototype.readAsBinaryString = function (f) {
				if (!this.onload)       //如果this未重写onload函数，则创建一个公共处理方式
					this.onload = e => {  //在this.onload函数中，完成公共处理
						let rs = this.reading();
						console.log(rs);
					};
				this.readAsArrayBuffer(f);  //内部会回调this.onload方法
			};
		},




  };
  </script>
  
  <style scoped>
  .drag-box {
    user-select: none;
    background-color: rgba(40, 40, 40, 0.5);
    z-index: 3;
    position: fixed;
    left: 255px;
    top: 230px;
    height: 470px;
    width: 800px;
  }
  
  #batchImportShipDragBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: #2a3f7eeb;
    /* background: url("../../assets/img/11.png"); */
    background-repeat: no-repeat;
    color: white;
    background-size: 100%;
    /* width: 100%; */
    height: 40px;
    cursor: move;
    user-select: none;
    padding:0 0 0 10px;
  }
  
  .no-select {
    user-select: none;
  }
  
  .pointer-events {
    pointer-events: none;
  }
  
  .no-border {
    border: none;
  }
  
  #batchImportShipInjectedBox {
    /* height: 107px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background: #eee; */
  }
  
  .add_area_name {
    width: 100%;
    display: flex;
    padding: 10px;
    -webkit-box-align: center;
    align-items: center;
  }
  
  .rectangle_position {
    box-sizing: border-box;
    height: 120px;
    /* overflow: auto; */
    padding: 10px;
  }
  
  .position_item {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 3px;
    color: white;
  }
  .latitude,
  .longtitude {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .S {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc5MEYzREUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc5MEYzREYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzkwRjNEQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzkwRjNERDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgAMKzEAAACLSURBVHjaYuSdefUZAxUAEwOVwOAziAWXxKc0LUl0Mb5Z156TZBDMEP7MCZ9gYh+nF/CBxHEZRtBr/w3dvoIwjM94fhc3SV7D5i1choAtwJWOSA0jRnwJEtkFoDDCZxjewOabxYCsiQ9mOHKYERXY+MKEojCCJQdsLmIhNZawGUIw+nFpGmG5HyDAADHCQ05+Tp3dAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .N {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTczQTZFMjcwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTczQTZFMjgwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzNBNkUyNTBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzNBNkUyNjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlkB4AIAAABkSURBVHjaYuSdefUZAxUAEwOVwBAx6FOaliQIEytO0EW4NDGe38VNstdwGUZWGGFzAUkG8WdO+ASiP04v4KPYRXyzrj0nxov0TUcwV1HFRYQMY8GlCVtM4RIHx+xo7icIAAIMAC3mJnEAIFI3AAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  
  .W {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc2NDE2RDQwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc2NDE2RDUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzY0MTZEMjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzY0MTZEMzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm30OHsAAAB8SURBVHjaYuSdefUZAxUAEwOVwCA36FOaliQIkyOG1UWM53dxwzQga8aljmSvgTTCDOTPnPAJp4v4Zl17DqI/Ti/gI8bg/4ZuXwm6CNlmmO34LGAi1Wb6pyNYOKEHKC5xol2Ey1vo4izYFIFsR04jhMTBSWM09xMEAAEGAGRTQHGIQYVKAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .E {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAIAAADZrBkAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTcyMkM3MUUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTcyMkM3MUYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzIyQzcxQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzIyQzcxRDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjeAQ9kAAABMSURBVHjaYlRdfJOBdMDEQBagrzYWNP6tGDVMRWpLbhHQBgEyhdPQRLiMXQhrw1RHG79BwJP+rFG/Ian4dnYPQTezkOqrIZQDAAIMAA/OFUiIp0vUAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .NSEW {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  
  .selectInput {
    display: inline-block;
    width: 150px;
  }
  
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    width: 6px;
    background: rgba(#101f1c, 0.1);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.5);
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, 0.3);
  }

  .red{
    color:red;
  }
  .green{
    color:#29FB29;
  }
  .gray{
    color:gray
  }
  </style>
  