<template>
  <!-- <mapLayer></mapLayer> -->
  <div id="areaEditorDrag" class="drag-box">
    <div>
      <div id="areaEditorDragBar" class="no-select">
        <span>新增区域</span>
        <button
          style="
            background-color: transparent;
            border: none;
            font-size: 2em;
            cursor: pointer;
            color: #ffffff;
            height: 100%;
            padding:0 10px 0 10px;
          "
          @click="setAreaEditor(false)"
        >
          ×
        </button>
      </div>

      <div id="areaEditorInjectedBox">
        <div class="add_area_name">
          <div style="width: 50%; color: white">
            <span style="  color: #ff4949;padding-right: 2px;">*</span>名称：
            <el-input style="width: 140px" v-model="areaName"></el-input>
          </div>
          <div
            style="width: 50%; display: flex; align-items: center; position: relative; color: white"
          >
          <span style="  color: #ff4949;padding-right: 2px;">*</span>分组：
            <div style="display: flex">
              <el-select style="width: 143px" v-model="groupValue" @focus="checkGroup">
                <el-option v-for="item in groupOptions" :key="item.value" :label="item.label" :value="item.value">

                </el-option>
              </el-select>
              <!-- <i
                class="el-icon-plus"
                style="
                  border: 1px solid rgb(192, 192, 192);
                  width: 30px;
                  height: 30px;
                  line-height: 30px;
                  text-align: center;
                "
              ></i> -->
            </div>
          </div>
        </div>

        <div class="add_area_name" v-if="!regionId">
            <div class="area_select" >
                <span>绘制：</span>
                <el-select v-model="areaType.value" style="width: 162px" @change="resetArea">
                    <el-option v-for="item in areaType.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            <el-button @click="getAreaFromMap" style="margin-left: 20px;">在地图上绘制</el-button>
        </div>
        </div>

        <div class="rectangle_position" v-if="!regionId && (areaType.value == 1 || areaType.value == 2)">
        
            <div class="position_item" >
            <div  style="text-align: center; width: 40%;">
                <span>纬度</span>
            </div>
            <div  style="text-align: center; width: 50%;" >
                <span>经度</span>
            </div>
          </div>
          <div class="position_item" v-for="(point,index) in points">
            <div class="latitude">
              <div>{{ index+1 }}. <el-input :disabled="regionId" style="width: 162px" v-model="point[0]" @input="latlonChange(index)"></el-input> °</div>

              <div v-if="point[0] >= 0" class="N NSEW"></div>
              <div v-else class="S NSEW"></div>
            </div>
            <div class="longtitude">
              <div><el-input :disabled="regionId" style="width: 162px" v-model="point[1]"  @input="latlonChange(index)"></el-input> °</div>
              <div v-if="point[1] >= 0" class="E NSEW"></div>
              <div v-else class="W NSEW"></div>
            </div>
          </div>

        </div>
        <div class="rectangle_position" v-if="!regionId && !(areaType.value == 1 || areaType.value == 2)">
            <div class="position_item">
                圆心：
                <div class="latitude">
                    <div> <el-input :disabled="regionId" style="width: 162px" v-model="circle.i[0]" @input="latlonChange()"></el-input> °</div>

                    <div v-if="circle.i[0] >= 0" class="N NSEW"></div>
                    <div v-else class="S NSEW"></div>
                </div>
                <div class="longtitude">
                    <div><el-input :disabled="regionId" style="width: 162px" v-model="circle.i[1]"  @input="latlonChange()"></el-input> °</div>
                    <div v-if="circle.i[1]" class="E NSEW"></div>
                    <div v-else class="W NSEW"></div>
                </div>

            </div>
            <div class="position_item">
                半径：
                <div class="latitude">
                    <div> <el-input :disabled="regionId" style="width: 162px" v-model="circle.r" @input="latlonChange()"></el-input> 米</div>
                </div>
                <!-- <div class="longtitude">

                </div> -->

            </div>


    </div>

        <div style="width: 100%; text-align: center;margin: 15px;">
          <el-row :gutter="0" style="margin-bottom: 10px">
            <el-col :span="12"><el-button @click="submitArea">保存</el-button></el-col>
            <el-col :span="12"><el-button @click="setAreaEditor(false)">取消</el-button></el-col>
          </el-row>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>

import {getGroupByUserId, addArea,updateRegion} from  "@/utils/api";
export default {
  name: "areaEditor",
  data() {
    return {
      lat1: this.$store.state.selectedArea.minLat ? this.$store.state.selectedArea.minLat :1,
      lon1: this.$store.state.selectedArea.minLon ? this.$store.state.selectedArea.minLon :1,
      lat2: this.$store.state.selectedArea.maxLat ? this.$store.state.selectedArea.maxLat :1,
      lon2: this.$store.state.selectedArea.maxLon ? this.$store.state.selectedArea.maxLon :1,
      points:[["0","0"],["0","0"]],
      areaName: this.$store.state.selectedArea.regionName ? this.$store.state.selectedArea.regionName :"",
    //   areaName: "",
      groupOptions:[],
      groupValue:this.$store.state.selectedArea.groupId ? this.$store.state.selectedArea.groupId :"",
    //   groupValue:"",
        areaType:{
            value:1,
            options:[{value:1, label:"矩形"},{ value: 2,label:"多边形"},{value: 3, label:"圆形"}]
        },
        circle:{
            i:[0,0],
            r:0
        }
    };
  },
  components: {},
  computed: {
    regionId(){
        return this.$store.state.selectedArea.regionId;
    }
  },
  watch:{
    mapPoints:{
        handler(newV, oldV){
            console.log("change...")

            if(newV){
                this.points = newV
            }
        },
        deep:true
    },
    mapCircle:{
        handler(newV, oldV){
            if(newV){
            
                this.circle.i = [newV.i.lat, newV.i.lng]
                this.circle.r = newV.r
            }
        },
        deep:true
    }
  },
  
  props:["mapPoints","mapCircle"],
  mounted() {
    this.fillData()
    this.getAreaGroupList();
    let areaEditorInjectedBox = document.getElementById("areaEditorInjectedBox")
    let isMouseDown,
      initX,
      initY,
      height = areaEditorInjectedBox.offsetHeight,
      width = areaEditorInjectedBox.offsetWidth,
      areaEditorDragBar = document.getElementById("areaEditorDragBar"),
      areaEditorDrag = document.getElementById("areaEditorDrag");
    //   areaEditorDragBar = document.querySelector(".no-select");

    areaEditorDragBar.addEventListener("mousedown", function (e) {
        if(e.target.tagName != "BUTTON"){
      isMouseDown = true;
      document.body.classList.add("no-select");
      areaEditorInjectedBox.classList.add("pointer-events");
      initX = e.offsetX;
      initY = e.offsetY;
      //   areaEditorDrag.style.opacity = 0.5;
        }
    });

    areaEditorDragBar.addEventListener("mouseup", function (e) {
      mouseupHandler();
    });

    document.addEventListener("mousemove", function (e) {
      if (isMouseDown) {
        var cx = e.clientX - initX,
          cy = e.clientY - initY;
        if (cx < 0) {
          cx = 0;
        }
        if (cy < 0) {
          cy = 0;
        }
        if (window.innerWidth - e.clientX + initX < width + 16) {
          cx = window.innerWidth - width;
        }
        if (e.clientY > window.innerHeight - height - areaEditorDragBar.offsetHeight + initY) {
          cy = window.innerHeight - areaEditorDragBar.offsetHeight - height;
        }
        areaEditorDrag.style.left = cx + "px";
        areaEditorDrag.style.top = cy + "px";
      }
    });

    document.addEventListener("mouseup", function (e) {
      if (
        e.clientY > window.innerWidth ||
        e.clientY < 0 ||
        e.clientX < 0 ||
        e.clientX > window.innerHeight
      ) {
        mouseupHandler();
      }
    });

    function mouseupHandler() {
      isMouseDown = false;
      document.body.classList.remove("no-select");
      areaEditorInjectedBox.classList.remove("pointer-events");
      areaEditorDrag.style.opacity = 1;
    }

  },
  methods: {
    checkGroup(){
        if(!this.groupOptions || this.groupOptions.length == 0){
            this.$message.error("请先在左侧侧边栏创建分组")
        }
    },
    fillData(){
        if(this.regionId){
            this.points = [[this.$store.state.selectedArea.maxLat, this.$store.state.selectedArea.minLon],[this.$store.state.selectedArea.minLat, this.$store.state.selectedArea.maxLon]]
        }
    },
    resetArea(){
        this.$emit("clearListener")
        this.$emit("removeAddRectangle")
        if(this.areaType.value == 1){
            this.points = [["0","0"],["0","0"]]
        }else{
            this.points =[]
        }
    },
    setAreaEditor(visiableFlag,regionId=null,groupId=null,regionName=null) {
    this.$store.commit("SET_SHOW_AREAEDITOR", {show:visiableFlag, regionId:regionId, groupId:groupId, regionName:regionName});
      if(!visiableFlag){
        this.$emit("removeAddRectangle")
      }
    },
    getAreaGroupList(){
        let userId = localStorage.getItem("userId");
      getGroupByUserId({ userId: userId }).then((res) => {
        this.groupOptions = [];
        let that = this;
        res.data.resultData.forEach((item) => {
          that.groupOptions.push({
            label: item.groupName,
            value: item.groupId,
          });
        });
        console.log(this.groupOptions)
      });
    },
    exchangeLatLng(conditions){
        let results = []
        if(conditions){
            conditions.forEach(item =>{

                results.push([item[1],item[0]])
            })
        }
        return results

    },
    getMaxMinPoint(points,index){       // 找出除index节点外，其他点的最大最小经纬度
        let maxPoint = [-90,-180]
        let minPoint = [90,180]
        points.forEach((point, i)=>{
            point[0] = parseFloat(point[0])
            point[1] = parseFloat(point[1])
            if(i!=index){
                maxPoint[0] = maxPoint[0] >= point[0] ? maxPoint[0] : point[0]
                minPoint[0] = minPoint[0] <= point[0] ? minPoint[0] : point[0]
                maxPoint[1] = maxPoint[1] >= point[1] ? maxPoint[1] : point[1]
                minPoint[1] = minPoint[1] <= point[1] ? minPoint[1] : point[1]            }
        })
        return [maxPoint, minPoint]
    },
    latlonChange(index){
        if(this.areaType.value == 1 || this.areaType.value == 2){

            // let maxMinPoint = this.getMaxMinPoint(this.points,index)
            // let lat = this.points[index][0] - maxMinPoint[1][0] > 0.4 ? maxMinPoint[1][0]+0.4 : this.points[index][0];
            // lat = maxMinPoint[0][0] - lat > 0.4? maxMinPoint[0][0]-0.4 : lat
            // let lng = this.points[index][1] - maxMinPoint[1][1] > 0.4 ? maxMinPoint[1][1]+0.4 : this.points[index][1];
            // lng = maxMinPoint[0][1] - lng > 0.4? maxMinPoint[0][1]-0.4 : lng
            // this.$set(this.points,index, [lat, lng])

            this.$emit("drawAddRectangle", this.points, this.areaType.value);
            
        }else if(this.areaType.value == 3){
            this.circle.r = this.circle.r > 12000 ? 12000 : this.circle.r;
            this.$emit("drawAddRectangle", this.circle, this.areaType.value);
        }

    },
    getLonAndLat(lng, lat, brng, dist) {
        //大地坐标系资料WGS-84 长半径a=6378137 短半径b=6356752.3142 扁率f=1/298.2572236
        let a = 6378137;
        let b = 6356752.3142;
        let f = 1 / 298.257223563;

        let lon1 = lng * 1;
        let lat1 = lat * 1;
        let s = dist;
        let alpha1 = brng * (Math.PI / 180)//mapNumberUtil.rad(brng);
        let sinAlpha1 = Math.sin(alpha1);
        let cosAlpha1 = Math.cos(alpha1);

        //let tanU1 = (1 - f) * Math.tan(mapNumberUtil.rad(lat1));
        let tanU1 = (1 - f) * Math.tan(lat1 * (Math.PI / 180));
        let cosU1 = 1 / Math.sqrt((1 + tanU1 * tanU1)), sinU1 = tanU1 * cosU1;
        let sigma1 = Math.atan2(tanU1, cosAlpha1);
        let sinAlpha = cosU1 * sinAlpha1;
        let cosSqAlpha = 1 - sinAlpha * sinAlpha;
        let uSq = cosSqAlpha * (a * a - b * b) / (b * b);
        let A = 1 + uSq / 16384 * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
        let B = uSq / 1024 * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));

        let sigma = s / (b * A), sigmaP = 2 * Math.PI;
        while (Math.abs(sigma - sigmaP) > 1e-12) {
            var cos2SigmaM = Math.cos(2 * sigma1 + sigma);
            var sinSigma = Math.sin(sigma);
            var cosSigma = Math.cos(sigma);
            var deltaSigma = B * sinSigma * (cos2SigmaM + B / 4 * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
                B / 6 * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
            sigmaP = sigma;
            sigma = s / (b * A) + deltaSigma;
        }

        let tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1;
        let lat2 = Math.atan2(sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
            (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp));
        let lambda = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1);
        let C = f / 16 * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
        let L = lambda - (1 - C) * f * sinAlpha *
            (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));

        let revAz = Math.atan2(sinAlpha, -tmp); // final bearing

        let lngLatObj = { lng: lon1 + L * (180 / Math.PI), lat: lat2 * (180 / Math.PI) }
        return lngLatObj;
    },

    submitArea(){
        if(this.areaName && this.areaName != ""){
            if(this.groupValue && this.groupValue != ""){
                if(!this.regionId){


                    let regionName = this.areaName
                    let groupId = this.groupValue
                    
                    if(this.areaType.value != 3){
                        if(this.points && this.points.length != 0){
                                let maxMinPoints = this.getMaxMinPoint(this.points, -1)
                                let minLon = maxMinPoints[1][1];
                                let maxLon = maxMinPoints[0][1];
                                let minLat = maxMinPoints[1][0];
                                let maxLat = maxMinPoints[0][0];
                                console.log(maxMinPoints)
                                if(maxLat <= minLat+0.8){
                                    if(maxLon <= minLon+0.8){
                                    let conditions = ""
                                    if(this.areaType.value == 2){
                                        conditions = JSON.stringify(this.exchangeLatLng(this.points))
                                    }
                
                                    addArea({regionName:regionName, groupId:groupId, state:0,minLon:minLon, maxLon:maxLon,minLat:minLat,maxLat:maxLat, conditions:conditions, tag: this.areaType.value}).then(res =>{
                                        if(res.data.resultCode == 0){
                                            this.$emit("refreshAreaList")
                                            this.$message({message: "创建成功",type: "success"});
                                            
                                            this.setAreaEditor(false)
                                        }else{
                                            this.$message.error(res.data.errorDesc)
                                        }
                                    })
                                }else{
                                    this.$message.error("经度跨度应小于0.7")
                                }
                            }else{
                                this.$message.error("纬度跨度应小于0.7")
                            }
                        }else{
                            this.$message.error("请先绘制区域")
                        }


                    }else{
                        let minLon = this.getLonAndLat(this.circle.i[1], this.circle.i[0], 270, this.circle.r).lng
                        let maxLon = this.getLonAndLat(this.circle.i[1], this.circle.i[0], 90, this.circle.r).lng
                        let minLat = this.getLonAndLat(this.circle.i[1], this.circle.i[0], 180, this.circle.r).lat
                        let maxLat = this.getLonAndLat(this.circle.i[1], this.circle.i[0], 0, this.circle.r).lat
                        let conditions = [this.circle.i[1],this.circle.i[0],this.circle.r]

                        addArea({regionName:regionName, groupId:groupId, state:0,minLon:minLon, maxLon:maxLon,minLat:minLat,maxLat:maxLat, conditions:conditions, tag: this.areaType.value}).then(res =>{
                                if(res.data.resultCode == 0){
                                    this.$emit("refreshAreaList")
                                    this.$message({message: "创建成功",type: "success"});
                                    
                                    this.setAreaEditor(false)
                                }else{
                                    this.$message.error(res.data.errorDesc)
                                }
                            })
                        

                    }
 

                }else{
                    let regionName = this.areaName
                    let groupId = this.groupValue
                    let regionId = this.regionId
                    updateRegion({regionName:regionName, groupId:groupId,regionId:regionId}).then(res=>{
                        if(res.data.resultCode == 0){
                                this.$emit("refreshAreaList")
                                this.$message({message: "创建成功",type: "success"});
                                
                                this.setAreaEditor(false)
                            }else{
                                this.$message.error(res.data.errorDesc)
                            }
                    })
                }

            }else{
                this.$message.error("区域分组不能为空")
            }

        }else{
            this.$message.error("区域名称不能为空")
        }
    },

    getAreaFromMap(){
        if(this.areaType.value == 1){
            this.$emit("drawRectangle")
        }else if(this.areaType.value == 2){
            this.$emit("drawPolygon")
            console.log("ssszoulema")
        }else if(this.areaType.value == 3){
            this.$emit("drawCircle")
        }
    }
  
},
};
</script>

<style scoped>
.drag-box {
  user-select: none;
  background-color: rgba(40, 40, 40, 0.5);
  z-index: 3;
  position: fixed;
  left: 455px;
  top: 230px;
  width: 516px;
}

#areaEditorDragBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #2a3f7eeb;
  color: white;
  /* width: 100%; */
  height: 40px;
  cursor: move;
  user-select: none;
  padding:0 0 0 10px;
}

.no-select {
  user-select: none;
}

.pointer-events {
  pointer-events: none;
}

.no-border {
  border: none;
}

#areaEditorInjectedBox {
  /* height: 107px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: 2rem; */
  /* background: #eee; */
}

.add_area_name {
  width: 100%;
  display: flex;
  padding: 10px;
  -webkit-box-align: center;
  align-items: center;
}

.rectangle_position {
  box-sizing: border-box;
  /* height: 120px; */
  max-height: 300px;
  overflow: auto; 
  padding: 0 10px 0 10px;
}
.area_select{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    color: white;
}

.position_item {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 3px;
  color: white;
}
.latitude,
.longtitude {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.S {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc5MEYzREUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc5MEYzREYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzkwRjNEQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzkwRjNERDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgAMKzEAAACLSURBVHjaYuSdefUZAxUAEwOVwOAziAWXxKc0LUl0Mb5Z156TZBDMEP7MCZ9gYh+nF/CBxHEZRtBr/w3dvoIwjM94fhc3SV7D5i1choAtwJWOSA0jRnwJEtkFoDDCZxjewOabxYCsiQ9mOHKYERXY+MKEojCCJQdsLmIhNZawGUIw+nFpGmG5HyDAADHCQ05+Tp3dAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}
.N {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTczQTZFMjcwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTczQTZFMjgwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzNBNkUyNTBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzNBNkUyNjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlkB4AIAAABkSURBVHjaYuSdefUZAxUAEwOVwBAx6FOaliQIEytO0EW4NDGe38VNstdwGUZWGGFzAUkG8WdO+ASiP04v4KPYRXyzrj0nxov0TUcwV1HFRYQMY8GlCVtM4RIHx+xo7icIAAIMAC3mJnEAIFI3AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}

.W {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc2NDE2RDQwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc2NDE2RDUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzY0MTZEMjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzY0MTZEMzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm30OHsAAAB8SURBVHjaYuSdefUZAxUAEwOVwCA36FOaliQIkyOG1UWM53dxwzQga8aljmSvgTTCDOTPnPAJp4v4Zl17DqI/Ti/gI8bg/4ZuXwm6CNlmmO34LGAi1Wb6pyNYOKEHKC5xol2Ey1vo4izYFIFsR04jhMTBSWM09xMEAAEGAGRTQHGIQYVKAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}
.E {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAIAAADZrBkAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTcyMkM3MUUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTcyMkM3MUYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzIyQzcxQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzIyQzcxRDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjeAQ9kAAABMSURBVHjaYlRdfJOBdMDEQBagrzYWNP6tGDVMRWpLbhHQBgEyhdPQRLiMXQhrw1RHG79BwJP+rFG/Ian4dnYPQTezkOqrIZQDAAIMAA/OFUiIp0vUAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 50%;
}
.NSEW {
  height: 40px;
  line-height: 40px;
  width: 40px;
}





::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#dbf3ee, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(239, 241, 244, 0.799);
  background-clip: padding-box;
  min-height: 20px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color .3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(217, 223, 235, 0.865);
}

</style>
