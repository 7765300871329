<template>
    <!-- <mapLayer></mapLayer> -->
    <div id="warningEditorDrag" class="drag-box" :style="{width: warningId != null? '268px':'516px'}">
      <div>
        <div id="warningEditorDragBar" class="no-select">
          <span v-if="warningId == null">新增预警</span>
          <span v-else>重命名</span>
          <button
            style="
            background-color: transparent;
            border: none;
            font-size: 2em;
            cursor: pointer;
            color: #ffffff;
            height: 100%;
            padding:0 10px 0 10px;
            "
            @click="setWarningEditor(false)"
          >
            ×
          </button>
        </div>

        <div id="warningEditorInjectedBox" v-if="warningId != null">
          <div class="add_area_name">
            <div style=" color: white;">
              任务名称：
              <el-input style="width: 140px" placeholder="请输入任务名称" v-model="warningName"></el-input>
            </div>
          </div>

          <div style="width: 100%; text-align: center">
            <el-row :gutter="0" style="margin-bottom: 10px;margin-top: 10px;">
              <el-col :span="12"><el-button @click="submitWarning">保存</el-button></el-col>
              <el-col :span="12"><el-button @click="setWarningEditor(false)">取消</el-button></el-col>
            </el-row>
          </div>
          </div>

        <div id="warningEditorInjectedBox" v-if="warningId == null">
          <div class="add_area_name">
            <div style="width: 50%; color: white">
              <span style="  color: #ff4949;padding-right: 2px;">*</span>任务名称：
              <el-input style="width: 140px" placeholder="请输入任务名称" v-model="warningName"></el-input>
            </div>
            <div
              style="width: 50%; display: flex; align-items: center; position: relative; color: white"
            >

            <span style="  color: #ff4949;padding-right: 2px;">* </span><span >任务分组：</span>
              <div style="display: flex"  v-if="warningId == null">
                <el-select style="width: 143px" v-model="warningSelect.value" placeholder="请选择任务分组">
                  <el-option v-for="item in warningSelect.option" :key="item.value" :label="item.label" :value="item.value">

                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="menu_line" ></div>

          <div class="add_area_name" >
            <div style=" color: white">
                <span style="  color: #ff4949;padding-right: 8px;"></span>预警区域：
              <el-select style="width: 143px" v-model="areaGroupSelect.value" placeholder="请选择区域分组" @change="getAreaList" clearable>
                  <el-option v-for="item in areaGroupSelect.option" :key="item.value" :label="item.label" :value="item.value">

                  </el-option>
                </el-select>
                ---
                <el-select style="width: 143px" v-model="areaSelect.value" placeholder="请选择预警区域" clearable>
                  <el-option v-for="item in areaSelect.option" :key="item.value" :label="item.label" :value="item.value">

                  </el-option>
                </el-select>

            </div>
          </div>

          <div class="add_area_name" >
            <div style=" color: white">
                <span style="  color: #ff4949;padding-right: 2px;">*</span>预警船队：
              <el-select style="width: 143px" v-model="fleetSelect.value" placeholder="请选择预警船队" clearable>
                  <el-option v-for="item in fleetSelect.option" :key="item.value" :label="item.label" :value="item.value">

                  </el-option>
                </el-select>

            </div>
          </div>

          <div class="menu_line" ></div>

          <div class="add_area_name" >
            <div style="width: 50%; color: white">
              船舶船旗：
              <el-select style="width: 143px" v-model="flagSelect.value" placeholder="请选择船舶船旗"     multiple
              collapse-tags filterable @change="selectChange">
                  <el-option v-for="item in flagSelect.option" :key="item.value" :label="item.label" :value="item.value">

                  </el-option>
                </el-select>
            </div>
            <div
              style="width: 50%; display: flex; align-items: center; position: relative; color: white"
            >
              船舶类型：
              <div style="display: flex">
                <el-select style="width: 143px" v-model="typeSelect.value" placeholder="请选择船舶类型" multiple
                collapse-tags filterable @change="selectChange">
                  <el-option v-for="item in typeSelect.option" :key="item.value" :label="item.label" :value="item.value">

                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="add_area_name" >
            <div style="width: 50%; color: white">
              船舶吨位：
              <el-select style="width: 143px" v-model="tonSelect.value" placeholder="请选择船舶吨位" multiple
              collapse-tags filterable @change="selectChange">
                  <el-option v-for="item in tonSelect.option" :key="item.value" :label="item.label" :value="item.value">

                  </el-option>
                </el-select>
            </div>
            <div
              style="width: 50%; display: flex; align-items: center; position: relative; color: white"
            >
              船舶事件：
              <div style="display: flex">
                <el-select style="width: 143px" v-model="eventSelect.value" placeholder="请选择船舶事件" multiple
                collapse-tags filterable @change="selectChange">
                  <el-option v-for="item in eventSelect.option" :key="item.value" :label="item.label" :value="item.value">

                  </el-option>
                </el-select>
              </div>
            </div>
          </div>




          <div style="width: 100%; text-align: center">
            <el-row :gutter="0" style="margin-bottom: 10px;margin-top: 20px;">
              <el-col :span="12"><el-button @click="submitWarning">保存</el-button></el-col>
              <el-col :span="12"><el-button @click="setWarningEditor(false)">取消</el-button></el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>

  import {getGroupByUserId, addArea,updateRegion,getRegionByGroupId,getFleetsByUserId,getAllFlag,getAllType,getMissionGroupByUserId,addNewMission,renameMission} from  "@/utils/api";
  export default {
    name: "warningEditor",
    data() {
      return {
        warningName:this.$store.state.showWarningEditor.warningName,
        warningSelect:{
            value:"",
            option:[]
        },
        areaGroupSelect:{
            value:"",
            option:[]
        },
        areaSelect:{
            value:"",
            option:[]
        },
        fleetSelect:{
            value:"",
            option:[]
        },
        flagSelect:{
            value:[],
            option:[]
        },
        typeSelect:{
            value:[],
            option:[]
        },
        tonSelect:{
            value:[],
            option:[{value:"未知",label:"未知"},{value:"0-5000吨",label:"0-5000吨"},{value:"5001-10000吨",label:"5001-10000吨"},{value:"10001-50000吨",label:"10001-50000吨"},{value:"50001-100000吨",label:"50001-100000吨"},{value:"100001吨以上",label:"100001吨以上"}]
        },
        eventSelect:{
            value:[],
            option:[{value:1,label:"锚泊"},{value:2,label:"系泊"},{value:3, label:"海上停船"},{value:4,label:"减速"},{value:5,label:"重新航行"},{value:6, label:"ais信号消失"},{value:7, label:"ais信号出现"},{value:61, label:"ais信号疑似消失"},{value:8, label:"异常加速"}]
        }
      };
    },
    components: {},
    computed: {
      warningId(){
          return this.$store.state.showWarningEditor.warningId;
      }
    },
    mounted() {
        this.getWarningGroupList()
      this.getAreaGroupList();
      this.getFleets()
      this.getFlagList()
      this.getTypeList()
      let warningEditorInjectedBox = document.getElementById("warningEditorInjectedBox")
      let isMouseDown,
        initX,
        initY,
        height = warningEditorInjectedBox.offsetHeight,
        width = warningEditorInjectedBox.offsetWidth,
        warningEditorDragBar = document.getElementById("warningEditorDragBar"),
        warningEditorDrag = document.getElementById("warningEditorDrag");
      //   warningEditorDragBar = document.querySelector(".no-select");

      warningEditorDragBar.addEventListener("mousedown", function (e) {
        if(e.target.tagName != "BUTTON"){
        isMouseDown = true;
        document.body.classList.add("no-select");
        warningEditorInjectedBox.classList.add("pointer-events");
        initX = e.offsetX;
        initY = e.offsetY;
        //   warningEditorDrag.style.opacity = 0.5;
      }});

      warningEditorDragBar.addEventListener("mouseup", function (e) {
        mouseupHandler();
      });

      document.addEventListener("mousemove", function (e) {
        if (isMouseDown) {
          var cx = e.clientX - initX,
            cy = e.clientY - initY;
          if (cx < 0) {
            cx = 0;
          }
          if (cy < 0) {
            cy = 0;
          }
          if (window.innerWidth - e.clientX + initX < width + 16) {
            cx = window.innerWidth - width;
          }
          if (e.clientY > window.innerHeight - height - warningEditorDragBar.offsetHeight + initY) {
            cy = window.innerHeight - warningEditorDragBar.offsetHeight - height;
          }
          warningEditorDrag.style.left = cx + "px";
          warningEditorDrag.style.top = cy + "px";
        }
      });

      document.addEventListener("mouseup", function (e) {
        if (
          e.clientY > window.innerWidth ||
          e.clientY < 0 ||
          e.clientX < 0 ||
          e.clientX > window.innerHeight
        ) {
          mouseupHandler();
        }
      });

      function mouseupHandler() {
        isMouseDown = false;
        document.body.classList.remove("no-select");
        warningEditorInjectedBox.classList.remove("pointer-events");
        warningEditorDrag.style.opacity = 1;
      }

    },
    methods: {
        selectChange(e){
            if(e.length > 5){
                this.$message.warning("最多只能选择5个")
                if(this.flagSelect.value.length > 5){
                    this.flagSelect.value.splice(-1)
                }
                if(this.tonSelect.value.length > 5){
                    this.tonSelect.value.splice(-1)
                }
                if(this.typeSelect.value.length > 5){
                    this.typeSelect.value.splice(-1)
                }
                if(this.eventSelect.value.length > 5){
                    this.eventSelect.value.splice(-1)
                }
            }
        },
        setWarningEditor(visiableFlag,  warningId=null){

            this.$store.commit("SET_SHOW_WARNINGEDITOR", {show:false,  warningId:null})
            let that = this
            setTimeout(function(){
                that.$store.commit("SET_SHOW_WARNINGEDITOR", {show:visiableFlag,  warningId:warningId})
            },10)

        },

        getWarningGroupList() {
      let userId = localStorage.getItem("userId");
      getMissionGroupByUserId({ userId: userId }).then((res) => {
        this.warningSelect.option = [];
        let that = this;
        res.data.resultData.forEach((item) => {
          that.warningSelect.option.push({
            label: item.name,
            value: item.id,

          });
        });
        // this.getAllArea()

      });
    },
      getAreaGroupList(){
          let userId = localStorage.getItem("userId");
        getGroupByUserId({ userId: userId }).then((res) => {
          this.areaGroupSelect.option = [];
          let that = this;
          res.data.resultData.forEach((item) => {
            that.areaGroupSelect.option.push({
              label: item.groupName,
              value: item.groupId,
            });
          });
        //   console.log(this.groupOptions)
        });
      },

      getAreaList(){
        let groupId = this.areaGroupSelect.value
        getRegionByGroupId({groupId:groupId}).then(res=>{
                if(res.data.resultCode == 0){
                    this.areaSelect.option = []
                    this.areaSelect.value = ""
                    res.data.resultData.forEach(item2=>{
                        this.areaSelect.option.push({
                            label:item2.regionName,
                            value:item2.regionId,
                        })
                    })
                }
            })
      },

      getFleets(){
        let userId = localStorage.getItem("userId");
        let that = this
        getFleetsByUserId({userId:userId}).then(res=>{
            if(res.data.resultCode== "0"){
                that.fleetSelect.option = []
                res.data.resultData.forEach(
                item=>{
                    that.fleetSelect.option.push({
                        label:item.name,
                        value:item.id
                    })
                }
            )
            }
        })

    },
        getFlagList(){

            getAllFlag({}).then(res=>{

                if(res.data.resultCode== "0"){
                this.flagSelect.option = []
                res.data.resultData.forEach(
                item=>{
                    if(item){
                        this.flagSelect.option.push({
                        label:item,
                        value:item
                    })
                    }

                }
            )
            }
            })
        },
        getTypeList(){
            getAllType({}).then(res=>{
                if(res.data.resultCode== "0"){
                this.typeSelect.option = []
                res.data.resultData.forEach(
                item=>{
                    if(item){
                        this.typeSelect.option.push({
                        label:item,
                        value:item
                    })
                    }
                }
            )
            }
            })
        },

      submitWarning(){
        let userId = localStorage.getItem("userId");
        if(this.warningId == null){
            if(this.warningName && this.warningName != ""){
              if(this.warningSelect.value && this.warningSelect.value != ""){

                  if(this.fleetSelect.value!=""){
                        let fleetId = this.fleetSelect.value ? this.fleetSelect.value : -1;
                        let regionId = this.areaSelect.value ? this.areaSelect.value : -1;
                        addNewMission({name:this.warningName, groupId:this.warningSelect.value, status:0, fleetId:fleetId,regionId:regionId,settingType:JSON.stringify(this.typeSelect.value),settingFlag:JSON.stringify(this.flagSelect.value),settingTon:this.tonSelect.value,settingAis:JSON.stringify(this.eventSelect.value),userId:userId
                        }).then(
                            res=>{
                                if(res.data.resultCode == '0'){
                                    this.$emit("refreshWarningList")
                                    this.$message({message:"创建成功",type:"success"})
                                    this.setWarningEditor(false)
                                }
                            }
                        )
                  }else{
                    this.$message.error("预警船队不能为空")
                  }

              }else{
                  this.$message.error("任务分组不能为空")
              }

          }else{
              this.$message.error("任务名称不能为空")
          }

        }else{
            if(this.warningName && this.warningName != ""){
                renameMission({name:this.warningName, id:this.warningId}).then(res=>{
                    if(res.data.resultCode == '0'){
                        this.$emit("refreshWarningList")
                        this.$message({message:"修改成功",type:"success"})
                        this.setWarningEditor(false)
                    }
                })
            }else{
                this.$message.error("任务名称不能为空")
            }

        }

      },
    },
  };
  </script>

  <style scoped>
  .drag-box {
    user-select: none;
    background-color: rgba(40, 40, 40, 0.5);
    z-index: 3;
    position: fixed;
    left: 455px;
    top: 250px;
    width: 516px;
  }

  #warningEditorDragBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: #2a3f7eeb;
    color: white;
    /* width: 100%; */
    height: 40px;
    cursor: move;
    user-select: none;
    padding:0 0 0 10px;
  }

  .no-select {
    user-select: none;
  }

  .pointer-events {
    pointer-events: none;
  }

  .no-border {
    border: none;
  }

  #warningEditorInjectedBox {
    /* height: 107px; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* font-size: 2rem; */
    /* background: #eee; */
  }

  .add_area_name {
    width: 100%;
    display: flex;
    padding: 10px;
    -webkit-box-align: center;
    align-items: center;
  }

  .rectangle_position {
    box-sizing: border-box;
    height: 120px;
    /* overflow: auto; */
    padding: 10px;
  }

  .position_item {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 3px;
    color: white;
  }
  .latitude,
  .longtitude {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .S {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc5MEYzREUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc5MEYzREYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzkwRjNEQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzkwRjNERDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgAMKzEAAACLSURBVHjaYuSdefUZAxUAEwOVwOAziAWXxKc0LUl0Mb5Z156TZBDMEP7MCZ9gYh+nF/CBxHEZRtBr/w3dvoIwjM94fhc3SV7D5i1choAtwJWOSA0jRnwJEtkFoDDCZxjewOabxYCsiQ9mOHKYERXY+MKEojCCJQdsLmIhNZawGUIw+nFpGmG5HyDAADHCQ05+Tp3dAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .N {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTczQTZFMjcwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTczQTZFMjgwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzNBNkUyNTBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzNBNkUyNjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlkB4AIAAABkSURBVHjaYuSdefUZAxUAEwOVwBAx6FOaliQIEytO0EW4NDGe38VNstdwGUZWGGFzAUkG8WdO+ASiP04v4KPYRXyzrj0nxov0TUcwV1HFRYQMY8GlCVtM4RIHx+xo7icIAAIMAC3mJnEAIFI3AAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .W {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTc2NDE2RDQwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTc2NDE2RDUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzY0MTZEMjBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzY0MTZEMzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm30OHsAAAB8SURBVHjaYuSdefUZAxUAEwOVwCA36FOaliQIkyOG1UWM53dxwzQga8aljmSvgTTCDOTPnPAJp4v4Zl17DqI/Ti/gI8bg/4ZuXwm6CNlmmO34LGAi1Wb6pyNYOKEHKC5xol2Ey1vo4izYFIFsR04jhMTBSWM09xMEAAEGAGRTQHGIQYVKAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .E {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAIAAADZrBkAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTcyMkM3MUUwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTcyMkM3MUYwQTM2MTFFMzlDNEZGMEU1OTk0RUVGQUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NzIyQzcxQzBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NzIyQzcxRDBBMzYxMUUzOUM0RkYwRTU5OTRFRUZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjeAQ9kAAABMSURBVHjaYlRdfJOBdMDEQBagrzYWNP6tGDVMRWpLbhHQBgEyhdPQRLiMXQhrw1RHG79BwJP+rFG/Ian4dnYPQTezkOqrIZQDAAIMAA/OFUiIp0vUAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .NSEW {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }

  .menu_line{
    height: 0.5px;
    background: white;
    margin-top: 5px;
    margin-bottom: 5px;
}
  </style>
