import L from 'leaflet';

class CrossDateLineMarker {
    constructor(latlng, options) {
        this.latlng = latlng;
        this.options = options;
        this._markers = [];
        this._map = null;
        
        // 添加 canvas layer 所需的属性和方法
        this.canvas = true;
        this._latlng = L.latLng(latlng);
        this._icon = options.icon;
        this._zIndex = options.zIndex || 1;
        this._tooltip = null;
        this._popup = null;
        
        // 添加事件系统
        this._events = {};
        this._eventParents = {};
        
        // 添加必要的标识和方法，确保被识别为 marker
        this.options.pane = 'markerPane';
        this.options.icon = options.icon;
        this._initIcon = () => {};
        this._removeIcon = () => {};
        this._initInteraction = () => {};
        this._reset = () => {};
        
        // 兼容 canvas layer 的方法
        this.getLatLng = () => this._latlng;
        this.setLatLng = (latlng) => {
            this._latlng = L.latLng(latlng);
            return this;
        };
        
        // 添加 tooltip 相关方法
        this.getTooltip = () => this._tooltip;
        this.setTooltip = (tooltip) => {
            this._tooltip = tooltip;
            return this;
        };
        
        // 添加 popup 相关方法
        this.getPopup = () => this._popup;
        this.setPopup = (popup) => {
            this._popup = popup;
            return this;
        };
        
        // 添加必要的 marker 接口方法
        this.update = () => {};
        this.isMarker = true;  // 标识这是一个 marker
    }

    // 修改 bindPopup 方法
    bindPopup(content, options) {
        // 保存 popup 内容和选项
        this._popupContent = content;
        this._popupOptions = options || {};
        
        // 为所有标记绑定 popup 和点击事件
        this._markers.forEach(marker => {
            // 为每个标记创建独立的 popup，确保位置正确
            if (content instanceof L.Popup) {
                // 克隆 popup 实例并设置正确的位置
                const popupClone = L.popup(content.options)
                    .setContent(content.getContent())
                    .setLatLng(marker.getLatLng());
                marker.bindPopup(popupClone);
            } else {
                // 创建新的 popup 并设置正确的位置
                marker.bindPopup(content, {
                    ...this._popupOptions,
                    // 确保 popup 显示在当前 marker 的位置
                    autoPan: this._popupOptions.autoPan !== false
                });
            }
            
            // 移除之前可能存在的点击事件处理器
            marker.off('click');
            
            // 添加点击事件处理，确保 popup 在当前 marker 位置打开
            marker.on('click', () => {
                // 使用 marker 自己的 popup，而不是共享的
                marker.openPopup();
            });
            
            // 重要：为每个标记添加 popupopen 事件监听，当标记的 popup 打开时，触发 CrossDateLineMarker 的 popupopen 事件
            marker.on('popupopen', (e) => {
                // 触发 CrossDateLineMarker 实例的 popupopen 事件
                this.fire('popupopen', {
                    popup: e.popup,
                    marker: marker
                });
            });
        });
        
        // 如果是 L.Popup 实例，直接使用
        if (content instanceof L.Popup) {
            this._popup = content;
        } else {
            // 否则创建新的 popup
            this._popup = L.popup(this._popupOptions).setContent(content);
        }
        
        // 确保 popup 设置了正确的位置
        this._popup.setLatLng(this._latlng);
        
        return this;
    }

    // 修改事件处理方法
    on(type, fn, context) {
        // 添加到事件系统
        if (!this._events[type]) {
            this._events[type] = [];
        }
        this._events[type].push({ fn, ctx: context });

        // 如果是普通模式，同时绑定到实际的 markers
        if (this._markers.length > 0) {
            this._markers.forEach(marker => {
                marker.on(type, fn, context);
            });
        }
        return this;
    }

    // 触发事件
    fire(type, data) {
        if (!this._events[type]) return this;
        
        const events = this._events[type].slice();
        events.forEach(event => {
            event.fn.call(event.ctx || this, {
                type: type,
                target: this,
                ...data
            });
        });
        return this;
    }

    // 修改 addTo 方法中的相关部分
    addTo(map) {
        if (map._type === 'canvasIconLayer') {
            this._map = map;
            return this;
        }

        // 普通地图添加方式保持不变
        const lat = parseFloat(this.latlng[0]);
        const lng = parseFloat(this.latlng[1]);
        
        const positions = [
            [lat, lng],
            [lat, Number(lng) + 360],
            [lat, Number(lng) - 360]
        ];

        this._clearMarkers();

        positions.forEach(pos => {
            const marker = L.marker(pos, this.options);
            this._markers.push(marker);
            marker.addTo(map);
            
            // 如果有 popup，为新创建的标记绑定 popup
            if (this._popup) {
                if (this._popupContent instanceof L.Popup) {
                    // 克隆 popup 实例并设置正确的位置
                    const popupClone = L.popup(this._popup.options)
                        .setContent(this._popup.getContent())
                        .setLatLng(marker.getLatLng());
                    marker.bindPopup(popupClone);
                } else {
                    // 创建新的 popup 并设置正确的位置
                    marker.bindPopup(this._popupContent, {
                        ...this._popupOptions,
                        // 确保 popup 显示在当前 marker 的位置
                        autoPan: this._popupOptions.autoPan !== false
                    });
                }
                
                // 添加点击事件处理
                marker.on('click', () => {
                    marker.openPopup();
                });
                
                // 重要：为每个标记添加 popupopen 事件监听，当标记的 popup 打开时，触发 CrossDateLineMarker 的 popupopen 事件
                marker.on('popupopen', (e) => {
                    // 触发 CrossDateLineMarker 实例的 popupopen 事件
                    this.fire('popupopen', {
                        popup: e.popup,
                        marker: marker
                    });
                });
            }
        });
        
        this._map = map;
        return this;
    }

    remove() {
        this._clearMarkers();
        return this;
    }

    _clearMarkers() {
        this._markers.forEach(marker => {
            if (marker && marker.remove) {
                marker.remove();
            }
        });
        this._markers = [];
    }
    
    // 添加 openPopup 方法
    openPopup(latlng) {
        if (this._popup) {
            // 如果提供了坐标，使用提供的坐标
            if (latlng) {
                this._popup.setLatLng(latlng);
            } else {
                // 否则使用标记的坐标
                this._popup.setLatLng(this._latlng);
            }
            
            // 如果地图存在，在地图上打开 popup
            if (this._map) {
                this._map.openPopup(this._popup);
                
                // 触发 popupopen 事件
                this.fire('popupopen', {
                    popup: this._popup
                });
            }
        }
        
        return this;
    }
    
    // 添加 closePopup 方法
    closePopup() {
        if (this._popup) {
            this._popup.close();
            
            // 触发 popupclose 事件
            this.fire('popupclose', {
                popup: this._popup
            });
        }
        
        return this;
    }

    // 重写 onAdd 方法，确保在添加到地图时正确处理 popup
    onAdd(map) {
        // 保存地图引用
        this._map = map;
        
        // 添加标记到地图
        this._markers.forEach(marker => {
            marker.addTo(map);
        });
        
        return this;
    }

    // 重写 onRemove 方法，确保在从地图移除时正确处理 popup
    onRemove(map) {
        // 关闭 popup
        this.closePopup();
        
        // 从地图移除标记
        this._markers.forEach(marker => {
            marker.removeFrom(map);
        });
        
        // 移除地图引用
        this._map = null;
        
        return this;
    }

    // 设置图标
    setIcon(icon) {
        this._markers.forEach(marker => {
            marker.setIcon(icon);
        });
        return this;
    }

    // 设置不透明度
    setOpacity(opacity) {
        this._markers.forEach(marker => {
            marker.setOpacity(opacity);
        });
        return this;
    }

    // 设置经纬度位置
    setLatLng(latlng) {
        const lat = parseFloat(latlng[0]);
        const lng = parseFloat(latlng[1]);
        
        const positions = [
            [lat, lng],
            [lat, Number(lng) + 360],
            [lat, Number(lng) - 360]
        ];

        this._markers.forEach((marker, index) => {
            marker.setLatLng(positions[index]);
        });
        return this;
    }

    // 设置 zIndexOffset
    setZIndexOffset(offset) {
        this._markers.forEach(marker => {
            marker.setZIndexOffset(offset);
        });
        return this;
    }

    // 设置是否可拖动
    setDraggable(draggable) {
        this._markers.forEach(marker => {
            marker.dragging[draggable ? 'enable' : 'disable']();
        });
        return this;
    }

    // 设置是否可见
    setVisible(visible) {
        this._markers.forEach(marker => {
            if (visible) {
                marker.addTo(this._map);
            } else {
                marker.remove();
            }
        });
        return this;
    }

    // 绑定 tooltip
    bindTooltip(content, options) {
        this._markers.forEach(marker => {
            marker.bindTooltip(content, options);
        });
        return this;
    }

    // 解绑 tooltip
    unbindTooltip() {
        this._markers.forEach(marker => {
            marker.unbindTooltip();
        });
        return this;
    }

    // 解绑 popup
    unbindPopup() {
        this._markers.forEach(marker => {
            marker.unbindPopup();
        });
        return this;
    }

    // 切换 popup
    togglePopup() {
        this._markers[0].togglePopup();
        return this;
    }

    // 是否有 popup
    isPopupOpen() {
        return this._markers[0].isPopupOpen();
    }
}

export default CrossDateLineMarker;