<template>
    <!-- <mapLayer></mapLayer> -->
    <div id="setShipLabelDrag" class="drag-box">
      <div id="setShipLabelDragBar" class="no-select">
        <span>自定义船舶标签</span>
        <button
          style="
          
            background-color: transparent;
            border: none;
            font-size: 2em;
            cursor: pointer;
            color: #ffffff;
            height: 100%;
            padding:0 10px 0 10px;
          "
          @click="setShipLabel(false)"
        >
          ×
        </button>
      </div>
  
      <div id="setShipLabelInjectedBox">
        <el-input
        v-model="fleetName"
        style="margin: 20px; width: 200px"
        placeholder="请输入自定义标签内容"
      ></el-input>
        <div style="width: 100%; text-align: center">
          <el-row :gutter="0" style="margin-bottom: 10px">
            <el-col :span="12"><el-button @click="addNewTip">保存</el-button></el-col>
            <el-col :span="12"><el-button @click="setShipLabel(false)">取消</el-button></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { addNewTip} from "@/utils/api/index.js";
  export default {
    name: "setShipLabel",
    data() {
      return {
        fleetName: this.$store.state.showShipLabel.fleetName,
        mmsi: this.$store.state.showShipLabel.mmsi
      };
    },
  
    components: {},

    mounted() {
      let setShipLabelInjectedBox = document.getElementById("setShipLabelInjectedBox");
      let isMouseDown,
        initX,
        initY,
        height = setShipLabelInjectedBox.offsetHeight,
        width = setShipLabelInjectedBox.offsetWidth,
        setShipLabelDragBar = document.getElementById("setShipLabelDragBar"),
        setShipLabelDrag = document.getElementById("setShipLabelDrag");
      //   setShipLabelDrag = document.querySelector(".drag-box");
      //   setShipLabelDragBar = document.querySelector(".no-select");
  
      setShipLabelDragBar.addEventListener("mousedown", function (e) {
        if(e.target.tagName != "BUTTON"){
        isMouseDown = true;
        document.body.classList.add("no-select");
        setShipLabelInjectedBox.classList.add("pointer-events");
        initX = e.offsetX;
        initY = e.offsetY;
        //   setShipLabelDrag.style.opacity = 0.5;
        }  
    });
  
      setShipLabelDragBar.addEventListener("mouseup", function (e) {
        mouseupHandler();
      });
  
      document.addEventListener("mousemove", function (e) {
        if (isMouseDown) {
          var cx = e.clientX - initX,
            cy = e.clientY - initY;
          if (cx < 0) {
            cx = 0;
          }
          if (cy < 0) {
            cy = 0;
          }
          if (window.innerWidth - e.clientX + initX < width + 16) {
            cx = window.innerWidth - width;
          }
          if (e.clientY > window.innerHeight - height - setShipLabelDragBar.offsetHeight + initY) {
            cy = window.innerHeight - setShipLabelDragBar.offsetHeight - height;
          }
          setShipLabelDrag.style.left = cx + "px";
          setShipLabelDrag.style.top = cy + "px";
        }
      });
  
      document.addEventListener("mouseup", function (e) {
        if (
          e.clientY > window.innerWidth ||
          e.clientY < 0 ||
          e.clientX < 0 ||
          e.clientX > window.innerHeight
        ) {
          mouseupHandler();
        }
      });
  
      function mouseupHandler() {
        isMouseDown = false;
        document.body.classList.remove("no-select");
        setShipLabelInjectedBox.classList.remove("pointer-events");
        setShipLabelDrag.style.opacity = 1;
      }
    },
    methods: {

    setShipLabel(visiableFlag, fleetName= null){

        this.$store.commit("SET_SHOW_SHIPLABEL", {show:false, fleetName: null})
        let that = this
        setTimeout(function(){
            that.$store.commit("SET_SHOW_SHIPLABEL", {show:visiableFlag, fleetName:fleetName})
        },10)
        
    },
  
    addNewTip() {

        if(!this.$store.state.showShipLabel.fleetId){
            let userId = localStorage.getItem("userId");
            let fleetName = this.fleetName
            let mmsi = this.mmsi
            if(fleetName){
                console.log({userId:userId, fleetName:fleetName})
                addNewTip({userId:userId, tip:fleetName, mmsi:mmsi}).then(res=>{
                if(res.data.resultCode == "0"){
                    this.$message({
                        message:"保存成功",
                        type: "success"
                    });
                    this.$emit("refreshFleetList")
                    this.setShipLabel(false)
                }else{
                    this.$message.error("保存失败")
                }
            })
            }
            else{
                this.$message.error("船名不能为空")
            }

        }else{
            let fleetId = this.$store.state.showShipLabel.fleetId
            let fleetName = this.fleetName

            if(fleetName){

                renameFleet({id:fleetId, fleetName:fleetName}).then(res=>{
                if(res.data.resultCode == "0"){
                    this.$message({
                        message:"保存成功",
                        type: "success"
                    });
                    this.$emit("refreshFleetList")
                    this.setShipLabel(false)
                }else{
                    this.$message.error("保存失败")
                }
            })
            }
            else{
                this.$message.error("船名不能为空")
            }



        }
        //   if(!this.$store.state.selectedGroupId){
        //       let userId = localStorage.getItem("userId");
        // console.log(userId);
        // addAreaGroup({ groupName: this.groupName, userId: userId }).then((res) => {
        //   if (res.data.resultCode == "0") {
        //     this.$emit("refreshAreaGroupList");
        //     this.$message({
        //       message: "保存成功",
        //       type: "success",
        //     });
        //     this.setJoinShip(false);
        //   } else {
        //     this.$message.error("保存失败");
        //   }
        // });
      
        //   }else{
        //       let groupId = this.$store.state.selectedGroupId
        //       let groupName = this.groupName
        //       renameRegionGroup({groupName:groupName, groupId}).then((res) => {
        //   if (res.data.resultCode == "0") {
        //     this.$emit("refreshAreaGroupList");
        //     this.$message({
        //       message: "保存成功",
        //       type: "success",
        //     });
        //     this.setJoinShip(false);
        //   } else {
        //     this.$message.error("保存失败");
        //   }})
        //   }
  
      },
  
  
    },
  };
  </script>
  
  <style scoped>
  .drag-box {
    user-select: none;
    background-color: rgba(40, 40, 40, 0.5);
    z-index: 3;
    position: fixed;
    right: 450px;
    top: 200px;
    width: 240px;
  }
  
  #setShipLabelDragBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: #2a3f7eeb;
    color: white;
    /* width: 100%; */
    height: 40px;
    cursor: move;
    user-select: none;
    padding:0 0 0 10px;
  }
  
  .no-select {
    user-select: none;
  }
  
  .pointer-events {
    pointer-events: none;
  }
  
  .no-border {
    border: none;
  }
  
  #setShipLabelInjectedBox {
    /* height: 107px; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* font-size: 2rem; */
    /* background: #eee; */
  }
  </style>
  